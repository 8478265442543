import { Alert, Button, CircularProgress, TextField, TextFieldProps } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useAdminStore } from '@tom-ui/admin'
import { containerService } from '@tom-ui/planning'
import { useMinimalsTheme } from '@tom-ui/ui'
import { IsoCodeMappingFormDialog } from 'modules/admin/src/pages/IsoCodeMapping/components/IsoCodeMappingForm'
import { ContainerHeight, ContainerType } from 'modules/planning/src/app/api'
import { useState } from 'react'
import {
  Controller,
  FieldErrors,
  FieldValues,
  FormState,
  UseControllerProps,
  UseFormWatch,
} from 'react-hook-form'

type FieldProps = Pick<
  TextFieldProps,
  'label' | 'disabled' | 'fullWidth' | 'variant' | 'multiline' | 'maxRows' | 'minRows' | 'autoFocus'
>

export interface IControlledIsoCodeMappingInputProps<T extends FieldValues>
  extends UseControllerProps<T>,
    FieldProps {
  type?: string
  uppercase?: boolean
  endAdornment?: React.ReactNode
  formState: FormState<T>
  formArrayIndex?: number
  maxLength?: number
  required?: boolean
  setValue?: (value: string) => void
  watch: UseFormWatch<T>
}

export const getContainerCharacteristicsByIsoCode = async (isoCode: string) => {
  const characteristics = await containerService.getContainerCharacteristicsByIsoCode(
    isoCode?.toUpperCase(),
    false,
  )

  return characteristics
}

export const ControlledIsoCodeMappingInput = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth,
  disabled,
  variant,
  type,
  formState,
  required,
  watch,
}: IControlledIsoCodeMappingInputProps<T>) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const { isoCodeMappingUIStore } = useAdminStore()
  const { errors } = formState

  const [isIsoCodeValid, setIsIsoCodeValid] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const containerIsoCodeErrorText = (errors: FieldErrors) => {
    const isoCodeError = errors.isoCode
    if (isoCodeError) {
      const errorType = isoCodeError.type
      if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
      if (errorType === 'minLength' || errorType === 'maxLength')
        return t('isoCodeMustHave4Digits', 'ISO Code must have 4 digits.')

      return isoCodeError.message?.toString()
    }
  }

  const isoCodeInput = watch(name)

  return (
    <>
      <Controller
        rules={{ required, minLength: 4, maxLength: 4, validate: () => isIsoCodeValid }}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors[name]}
            helperText={containerIsoCodeErrorText(errors)}
            label={label}
            type={type}
            fullWidth={fullWidth}
            disabled={disabled}
            required={required}
            variant={variant ?? 'outlined'}
            onChange={async event => {
              event.target.value = event.target.value.toUpperCase()
              field.onChange(event)

              const isoCode = event.target.value
              if (isoCode.length === 4) {
                try {
                  setIsLoading(true)
                  const isValid = !!(await getContainerCharacteristicsByIsoCode(isoCode))
                  setIsLoading(false)
                  setIsIsoCodeValid(isValid)
                } finally {
                  setIsLoading(false)
                }
              } else {
                setIsIsoCodeValid(false)
              }
            }}
            inputProps={{
              maxLength: 4,
            }}
            InputProps={{
              endAdornment: <>{isLoading && <CircularProgress size={20} />}</>,
            }}
            data-cy='container-iso-code-field'
          />
        )}
        name={name}
        control={control}
      />
      {!isLoading && !isIsoCodeValid && isoCodeInput?.length === 4 && (
        <Alert
          sx={{ marginTop: theme.customSpacing.s }}
          severity='warning'
          action={
            <Button
              color='inherit'
              size='small'
              onClick={() =>
                isoCodeMappingUIStore.toggleFormDialog(true, {
                  isoCode: isoCodeInput,
                  height: ContainerHeight.Standard,
                  type: ContainerType.General,
                })
              }
            >
              {t('create', 'Create')}
            </Button>
          }
        >
          {t('unknownIsoCode', 'Unknown ISO code')}
          <br />
          {t('checkForTyposOrCreateCustomMapping', 'Check for typos or create a custom mapping')}
        </Alert>
      )}

      <IsoCodeMappingFormDialog
        viewStore={isoCodeMappingUIStore}
        onFilter={getContainerCharacteristicsByIsoCode}
        onSubmitFn={() => setIsIsoCodeValid(true)}
      />
    </>
  )
}
