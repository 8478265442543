import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material'
import {
  CustomsResponseDto,
  IssueType,
  OrderIssueResolutionType,
  ResolveOrderIssueCommand,
  TransportData,
} from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { IContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourney'
import { issueService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useFormStore } from '@tom-ui/utils'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { IssueResolutionViewStore } from '../../Stores/IssueResolutionViewStore'
import { requiredTransportDataResolution } from '../../Stores/IssuesViewStore'
import { NotesField } from '../atoms/NotesField'
import { ResolveIssueCard } from '../molecules/ResolveIssueCard'
import { ResolveIssuesFormList } from '../organisms/ResolveIssuesFormList'

interface Props {
  store: IssueResolutionViewStore
}

export type ResolveIssuesFormData = {
  notes: string
  selectedIssueResolution: string
  changeTransport: TransportData
  customs: CustomsResponseDto[]
}

export const ResolveIssueDialog = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { appViewStore, railcarItemStore } = usePlanningStore()

  const title = t('resolveIssue', 'Resolve Issue')

  const formStore = useFormStore<ResolveIssuesFormData>()

  const handleDialogClose = () => {
    store.closeDialog()
  }

  const getAlertMessage = (
    issueType?: IssueType,
    data?: IContainerJourney,
    railcarName?: string,
  ) => {
    if (!data) return

    const order = data.inboundOrder?.order ?? data.outboundOrder?.order

    if (issueType === IssueType.MissingRailcar) {
      return t(
        'railcarIsMissingAndContainersNeedsReplan',
        'Railcar {railcarName} is missing - Container {containerNumber} needs to be replanned for loading.',
        {
          railcarName: railcarName ?? '',
          containerNumber: order?.containerNumber ?? '',
        },
      )
    }
  }

  const onSubmit = async () => {
    if (!store.issueToBeResolved || !formStore.validate()) {
      return
    }

    const formData = formStore.data

    const resolutionType = formData.selectedIssueResolution as OrderIssueResolutionType
    const issueId = store.issueToBeResolved.id

    const cmd: ResolveOrderIssueCommand = {
      notes: formData.notes,
      issueId,
      resolutionType,
      transportData: requiredTransportDataResolution.includes(resolutionType)
        ? (formData.changeTransport as TransportData)
        : undefined,
      customs:
        resolutionType === OrderIssueResolutionType.UpdateCustomsStatus
          ? formData.customs
          : undefined,
    }

    try {
      await issueService.resolveOrderIssue(cmd)

      appViewStore.setShowAlert(
        'success',
        resolutionType === OrderIssueResolutionType.FollowUp
          ? t('issueHasBeenMarkedForFollowUp', 'Issue has been marked for follow up')
          : t('issueHasBeenUpdated', 'Issue has been updated'),
      )

      // TODO: check if needed
      // reset()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToResolveIssue', 'Failed to resolve issue'))
    }

    handleDialogClose()
  }

  const order =
    store.issueToBeResolved?.containerJourney?.inboundOrder?.order ??
    store.issueToBeResolved?.containerJourney?.outboundOrder?.order
  const railcarId = order?.railcarId ?? ''
  const railcarName = _(railcarItemStore.elements).find(r => r.data.id === railcarId)?.data.name
  const alertMessage = getAlertMessage(
    store.issueToBeResolved?.data.issueType,
    store.issueToBeResolved?.containerJourney,
    railcarName,
  )
  return (
    <Dialog fullWidth maxWidth='lg' open={store.isOpen} onClose={handleDialogClose}>
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}`, mb: '1rem' }}>
        {title}
      </DialogTitle>

      <form>
        <DialogContent>
          <Box
            sx={{
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
              paddingBottom: '1.5rem',
              mb: '1rem',
            }}
          >
            <ResolveIssueCard issue={store.issueToBeResolved} />
          </Box>

          {alertMessage && (
            <Alert severity='warning' sx={{ mb: '2rem' }}>
              {alertMessage}
            </Alert>
          )}

          <ResolveIssuesFormList store={store} formStore={formStore} />

          <NotesField
            name='notes'
            formStore={formStore}
            initialValue={store.issueToBeResolved?.data.notes ?? ''}
          />
        </DialogContent>

        <DialogActions
          sx={{
            mt: theme.customSpacing.l,
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Stack direction={'row'} spacing={2}>
            <Button variant='contained' color='primary' onClick={onSubmit}>
              {t('confirm', 'Confirm')}
            </Button>
            <Button onClick={handleDialogClose} color='secondary'>
              {t('cancel', 'Cancel')}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
})
