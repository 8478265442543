import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PATHS } from '@host/app/paths'
import { ContainerYardOperationDialog } from '@host/components/container-change-position-dialog/ContainerYardOperationDialog'
import { ContainerYardOperationViewStore } from '@host/components/container-change-position-dialog/ContainerYardOperationViewStore'
import { Box, Button } from '@mui/material'
import { useOpenFeatureClient } from '@openfeature/react-sdk'
import { useTranslate } from '@tolgee/react'
import { useOperationsStore } from '@tom-ui/operations'
import {
  CarrierVisitActionBar,
  GateInGeneralCargoOrderListItem,
  InitializationWrapper,
  orderFilterDelegate,
  OrderGrouping,
  OrderGroupingStore,
  orderService,
  RemoveOrFinishOpenOrders,
  RestowListGroup,
  usePlanningStore,
  useWrappedLocalPagination,
  vesselVisitDetailPages,
  VesselVisitHeader,
  vesselVisitService,
} from '@tom-ui/planning'
import { IOrderItem } from '@tom-ui/planning/rt-stores/order/OrderItem'
import {
  GeneralCargoDamage,
  generalCargoDamageService,
  OutboundRequestValidationControl,
} from '@tom-ui/storage'
import { OfflineWrapper, useAsyncFetch } from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { CargoType, CarrierType, RestowResponseDto } from 'modules/planning/src/app/api'
import { CreateGeneralCargoOrder } from 'modules/planning/src/pages/Order/CreateGeneralCargoOrders'
import { OutboundOrderValidationDto } from 'modules/planning/src/stores/gateControl/ValidateOutboundDto'
import { Breadcrumbs, BreadcrumpItem } from 'modules/ui/src/theme/components/breadcrumbs/Breadcrumb'
import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { renderIsoCodeMappingInput } from '../IsoCodeMapping/IsoCodeMappingWrapper'
import { VesselVisitDashboard } from './VesselVisitDashboard'

export enum VesselVisitDirection {
  Inbound = 'Discharge',
  Outbound = 'Load',
}

export const CarrierVisitDirection = {
  Inbound: 'Inbound',
  Outbound: 'Outbound',
} as const

export type CarrierVisitDirection =
  (typeof CarrierVisitDirection)[keyof typeof CarrierVisitDirection]

export const CarrierVisitStatus = {
  Expected: 'Expected',
  Arrived: 'Arrived',
  InOperation: 'InOperation',
  Departed: 'Departed',
} as const

export type CarrierVisitStatus = (typeof CarrierVisitStatus)[keyof typeof CarrierVisitStatus]

// [OCTA-948] TODO: Adjust for GC
export const orderSortingDelegate = (_: any, a: IOrderItem, b: IOrderItem) => {
  return (a.data.containerNumber ?? '').localeCompare(b.data.containerNumber ?? '')
}

const restowSortingDelegate = (_: any, a: RestowResponseDto, b: RestowResponseDto) => {
  return (a.number ?? '').localeCompare(b.number ?? '')
}

const restowFilterDelegate = (filter: string, item: RestowResponseDto) => {
  if (item.number) return item.number.toLowerCase().includes(filter.toLowerCase())
  return false
}

export interface INnrOrderSummary {
  totalAmount: number
  totalUnassignedContainersForVisit: number
}

export const VesselVisitDetails = withAuthenticationRequired(
  observer(() => {
    const { id, page } = useParams()
    const currentPage =
      Object.keys(vesselVisitDetailPages).find(key => key === page?.toLowerCase()) ??
      vesselVisitDetailPages.dashboard

    const navigate = useNavigate()
    const vesselVisitId = Number(id)
    if (!vesselVisitId) {
      navigate(PATHS.vesselVisits)
    }

    const featureFlagClient = useOpenFeatureClient()
    const isRemoveOrFinishOpenOrdersWhenDepartureOn = useMemo(
      () => featureFlagClient.getBooleanValue('close-or-finish-open-orders-when-departure', false),
      [featureFlagClient],
    )

    const {
      appViewStore,
      vesselVisitItemStore,
      vesselVisitQueryService,
      tenantStore: planningTenantStore,
      generalCargoViewStore,
      restowItemStore,
      dialogStore,
    } = usePlanningStore()

    const { equipmentsVesselVisitUIStore, tenantConfigStore: operationsTenantConfigStore } =
      useOperationsStore()

    const containerYardOperationViewStore = useMemo(() => {
      return new ContainerYardOperationViewStore()
    }, [])

    const vesselVisitItem = computed(() => vesselVisitItemStore.elements[vesselVisitId]).get()

    useAsyncFetch(async () => {
      await vesselVisitQueryService.fetchById(vesselVisitId)
      await equipmentsVesselVisitUIStore.getPlannedEquipments(vesselVisitId)
    }, [vesselVisitId, vesselVisitQueryService, equipmentsVesselVisitUIStore])

    const orders = computed(() =>
      vesselVisitItemStore.ordersByVesselVisitId
        ? vesselVisitItemStore.ordersByVesselVisitId[vesselVisitId]
        : [],
    ).get()

    //Used to fetch orders damage when the visit is GC and the order list is available
    useEffect(() => {
      const fetchDamages = async () => {
        if (
          vesselVisitItem &&
          vesselVisitItem.data.cargoType === CargoType.GeneralCargo &&
          orders?.length
        ) {
          const result = await generalCargoDamageService.getOrderDamageAmount(orders.map(x => x.id))
          generalCargoViewStore.setDamageOrders(
            result.map(x => ({ amount: x.totalQuantity, orderId: x.orderId })),
          )
        }
      }

      fetchDamages()
    }, [generalCargoViewStore, orders, vesselVisitItem])

    const { t } = useTranslate()

    const handlePageChange = (page: string) => {
      if (page === `${PATHS.issues}`) {
        navigate(`${PATHS.issues}`, {
          state: { visitId: vesselVisitItem.id },
        })
      } else navigate(`${PATHS.vesselVisits}/${vesselVisitItem.id}/${page}`)
    }

    const renderDamage = (orderId: number) => <GeneralCargoDamage orderId={orderId} />

    const validateOutboundRequest = (params: OutboundOrderValidationDto): React.ReactElement => (
      <OutboundRequestValidationControl
        isOutbound={params.isOutbound}
        customerId={params.customerId}
        commodityId={params.commodityId}
        lotNumber={params.lotNumber}
        packageId={params.packageId}
        quantity={params.quantity}
        unitIds={params.unitIds}
        imoClasses={params.imoClasses}
      />
    )

    const handleEditOrder = async (order: IOrderItem) => {
      await generalCargoViewStore.getPackages()
      generalCargoViewStore.selectOrder(order.data)

      // [OCTA-948] TODO: Review and rework the store logic involved in lines below
      if (order.data.referenceNumber) {
        generalCargoViewStore.generalCargoSearchStore.setFilter(order.data.referenceNumber)
        await generalCargoViewStore.generalCargoSearchStore.fetch(
          generalCargoViewStore.generalCargoSearchStore.filter,
        )
      }

      dialogStore.openDialog(
        <CreateGeneralCargoOrder
          disableCarrierVisitSelection
          renderDamage={renderDamage}
          validateOutboundRequest={validateOutboundRequest}
        />,
      )
    }

    const handleDeleteOrder = async (id: number) => await orderService.delete(id)

    const isGeneralCargoVisit =
      vesselVisitItem && vesselVisitItem.data.cargoType === CargoType.GeneralCargo

    const dischargeOrdersGroupingStore = useMemo(
      () =>
        new OrderGroupingStore(() =>
          vesselVisitItem ? vesselVisitItem.discharge.containerOrders : [],
        ),
      [vesselVisitItem],
    )

    const getDischargeOrders = () =>
      isGeneralCargoVisit
        ? vesselVisitItem.discharge.generalCargoOrders
        : vesselVisitItem.discharge.containerOrders

    const getLoadOrders = () =>
      isGeneralCargoVisit
        ? vesselVisitItem.load.generalCargoOrders
        : vesselVisitItem.load.containerOrders

    const dischargeOrdersListStore = useWrappedLocalPagination(
      () => (vesselVisitItem ? getDischargeOrders() : []),
      undefined,
      orderSortingDelegate,
      orderFilterDelegate,
    )

    const loadOrdersGroupingStore = useMemo(
      () =>
        new OrderGroupingStore(() => (vesselVisitItem ? vesselVisitItem.load.containerOrders : [])),
      [vesselVisitItem],
    )

    const loadOrdersListStore = useWrappedLocalPagination(
      () => (vesselVisitItem ? getLoadOrders() : []),
      undefined,
      orderSortingDelegate,
      orderFilterDelegate,
    )

    const restowListStore = useWrappedLocalPagination(
      () => (vesselVisitItem ? vesselVisitItem.restows : []),
      undefined,
      restowSortingDelegate,
      restowFilterDelegate,
    )

    useEffect(() => {
      restowItemStore.fetchByVesselVisitId(vesselVisitId)
    }, [restowItemStore, vesselVisitId, vesselVisitItem])

    const dashboardActionButtons = [
      operationsTenantConfigStore.skipCraneSplit || isGeneralCargoVisit ? (
        <></>
      ) : (
        <Button
          key='plan-operations-button'
          variant='contained'
          size='large'
          onClick={() => navigate(`/EquipmentPlanning/${vesselVisitItem.id}/craneSplit`)}
          disabled={vesselVisitItem?.data.status === CarrierVisitStatus.Departed}
          data-cy='plan-vessel-visit-operations-btn'
        >
          {t('planOperations', 'Plan Operations')}
        </Button>
      ),
      !planningTenantStore.skipVesselVisitReadyForOperation &&
      vesselVisitItem?.data.status === CarrierVisitStatus.Arrived ? (
        <Button
          data-cy='start-operations-btn'
          key='start-operations-button'
          variant='contained'
          size='large'
          disabled={
            vesselVisitItem?.data.status !== CarrierVisitStatus.Arrived ||
            (!planningTenantStore.skipBerthTimestamp && !vesselVisitItem?.data.atb)
          }
          onClick={async () => {
            try {
              await vesselVisitService.startOperations(vesselVisitItem.id)
            } catch (error) {
              appViewStore.setShowAlert('error', 'Failed to start operations')
            }
          }}
        >
          {t('startOperations', 'Start Operations')}
        </Button>
      ) : (
        <></>
      ),
      !planningTenantStore.skipVesselVisitReadyForOperation &&
      vesselVisitItem?.data.status === CarrierVisitStatus.InOperation ? (
        <Button
          data-cy='end-operations-btn'
          key='end-operations-button'
          variant='contained'
          size='large'
          disabled={
            vesselVisitItem?.data.status !== CarrierVisitStatus.InOperation ||
            (!planningTenantStore.skipBerthTimestamp && !vesselVisitItem?.data.atb)
          }
          onClick={async () => {
            if (vesselVisitItem?.hasOpenOrders) {
              if (isRemoveOrFinishOpenOrdersWhenDepartureOn) {
                dialogStore.openDialog(
                  <RemoveOrFinishOpenOrders
                    visitItem={vesselVisitItem}
                    callback={async () =>
                      await vesselVisitService.endOperations(vesselVisitItem.id)
                    }
                  />,
                )
                return
              }

              const isConfirmed = await appViewStore.setOpenConfirmDialog(
                true,
                t(
                  'endOperationsWithOpenOrdersConfirmation',
                  'There are open orders for this carrier visit. Do you want to end operations despite that? Orders will remain open even after ending operations.',
                ),
                undefined,
              )
              if (!isConfirmed) return
            }
            try {
              await vesselVisitService.endOperations(vesselVisitItem.id)
            } catch (error) {
              appViewStore.setShowAlert('error', 'Failed to end operations')
            }
          }}
        >
          {t('endOperations', 'End operations')}
        </Button>
      ) : (
        <></>
      ),
    ]

    const DischargeList = observer(() => (
      <>
        {!isGeneralCargoVisit && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '1.5rem !important',
              paddingTop: '0 !important',
            }}
          >
            <OrderGrouping
              store={dischargeOrdersGroupingStore}
              containerYardOperationViewStore={containerYardOperationViewStore}
            />
          </Box>
        )}
        {isGeneralCargoVisit && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '1.5rem !important',
            }}
          >
            {dischargeOrdersListStore.pageItems.map(o => {
              return (
                <GateInGeneralCargoOrderListItem
                  key={`gate-in-order-list-item-${o.id}`}
                  order={{ ...o.data, carrierType: CarrierType.Vessel, damages: [] }}
                  damageAmount={
                    generalCargoViewStore.damageOrders.find(x => x.orderId === o.id)?.amount
                  }
                  highlight={dischargeOrdersListStore.filter}
                  onEdit={_ => handleEditOrder(o)}
                  onRemove={_ => handleDeleteOrder(o.id)}
                />
              )
            })}
          </Box>
        )}
      </>
    ))

    const LoadList = observer(() => (
      <>
        {!isGeneralCargoVisit && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '1.5rem !important',
              paddingTop: '0 !important',
            }}
          >
            <OrderGrouping
              store={loadOrdersGroupingStore}
              containerYardOperationViewStore={containerYardOperationViewStore}
              disableSequencing
            />
          </Box>
        )}
        {isGeneralCargoVisit && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '1.5rem !important',
            }}
          >
            {loadOrdersListStore.pageItems.map(o => {
              return (
                <GateInGeneralCargoOrderListItem
                  key={`gate-in-order-list-item-${o.id}`}
                  order={{ ...o.data, carrierType: CarrierType.Vessel, damages: [] }}
                  damageAmount={
                    generalCargoViewStore.damageOrders.find(x => x.orderId === o.id)?.amount
                  }
                  highlight={loadOrdersListStore.filter}
                  onEdit={_ => handleEditOrder(o)}
                  onRemove={_ => handleDeleteOrder(o.id)}
                />
              )
            })}
          </Box>
        )}
      </>
    ))

    const RestowList = observer(() => (
      <>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '1.5rem',
          }}
        >
          <RestowListGroup store={restowListStore} />
        </Box>
      </>
    ))

    const getBreadcrumbs = () => {
      if (!vesselVisitItem) return []

      const vessel = vesselVisitItem?.vessels?.[0]?.data

      if (!vessel) return []

      const carrierVisitLabel = `${vessel.name} <${vessel.shippingLine}>`

      let directionLabel = t('dashboard', 'Dashboard')

      if (page === vesselVisitDetailPages.discharge) {
        directionLabel = t('dischargeList', 'Discharge list')
      } else if (page === vesselVisitDetailPages.load) {
        directionLabel = t('loadList', 'Load list')
      } else if (page === vesselVisitDetailPages.restow) {
        directionLabel = t('restowList', 'Restow list')
      }

      const carrierVisitDashboardUrl = `${PATHS.vesselVisits}/${vesselVisitItem.id}/dashboard`

      const breadcrumbs: BreadcrumpItem[] = [
        {
          label: t('visits', 'Visits'),
          onClick: () => navigate('/visits'),
        },
        {
          label: carrierVisitLabel,
          onClick: () => navigate(carrierVisitDashboardUrl),
        },
        {
          label: directionLabel,
        },
      ]

      return breadcrumbs
    }

    return (
      <InitializationWrapper isInitializing={!vesselVisitItem}>
        <OfflineWrapper>
          <>
            <Breadcrumbs items={getBreadcrumbs()} />
            <VesselVisitHeader
              vesselVisitItem={vesselVisitItem}
              renderIsoCodeMappingInput={renderIsoCodeMappingInput}
            />

            <CarrierVisitActionBar
              visitType={'Vessel'}
              vesselVisitItem={vesselVisitItem}
              currentPage={currentPage}
              handlePageChange={(page: string) => handlePageChange(page)}
              dashboardActionButtons={
                !planningTenantStore.skipVesselVisitReadyForOperation ? dashboardActionButtons : []
              }
              dischargeOrdersListStore={
                !isGeneralCargoVisit ? dischargeOrdersGroupingStore : dischargeOrdersListStore
              }
              loadOrdersListStore={
                !isGeneralCargoVisit ? loadOrdersGroupingStore : loadOrdersListStore
              }
              restowListStore={restowListStore}
              validationOutboundRequest={validateOutboundRequest}
            />

            {currentPage === vesselVisitDetailPages.dashboard && (
              <VesselVisitDashboard
                vesselVisitItem={vesselVisitItem}
                handlePageChange={handlePageChange}
                visitId={vesselVisitId}
              />
            )}
            {currentPage === vesselVisitDetailPages.discharge && <DischargeList />}
            {currentPage === vesselVisitDetailPages.load && vesselVisitItem && <LoadList />}
            {currentPage === vesselVisitDetailPages.restow && vesselVisitItem && <RestowList />}
            <ContainerYardOperationDialog store={containerYardOperationViewStore} ignoreCheck />
          </>
        </OfflineWrapper>
      </InitializationWrapper>
    )
  }),
)
