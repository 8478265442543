import { Autocomplete, Button, Container, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useBooleanFlagValue, useOpenFeatureClient } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { CompanyType, CreateVesselCommand, UpdateVesselCommand, VesselDto } from '@planning/app/api'
import { CustomerAutoComplete } from '@planning/components/customer/CustomerAutoComplete'
import { vesselService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { countryCodeOptions } from './countryCodeOptions'

interface IVesselFormProps {
  vessel?: VesselDto
  vesselId?: number
  editMode?: boolean
}

export const VesselForm = observer(({ vessel, vesselId, editMode }: IVesselFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { appViewStore, drawerStore } = usePlanningStore()
  const isOrderNewCompanyFieldsActive = useBooleanFlagValue('order-changing-company-fields', false)
  const { t } = useTranslate()

  const createVessel = async (vessel: CreateVesselCommand) => {
    try {
      await vesselService.post(vessel)
      appViewStore.setShowAlert(
        'success',
        t('vesselCreatedSuccessfully', 'Vessel created successfully'),
      )
      drawerStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const updateVessel = async (vessel: UpdateVesselCommand) => {
    try {
      await vesselService.put(vessel)
      appViewStore.setShowAlert(
        'success',
        t('vesselUpdatedSuccessfully', 'Vessel updated successfully'),
      )
      drawerStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const featureFlagClient = useOpenFeatureClient()
  const vesselCountryCodeFF = useMemo(
    () => featureFlagClient.getBooleanValue('vessel-country-code', false),
    [featureFlagClient],
  )

  const onFormSubmit = async (data: any) => {
    if (editMode) {
      const updateCommand = { ...data, id: vesselId } as UpdateVesselCommand
      // Set default country code if feature flag is disabled
      if (!vesselCountryCodeFF) updateCommand.countryCode = vessel?.countryCode

      await updateVessel({
        ...updateCommand,
        countryCode: updateCommand.countryCode?.toUpperCase(),
      })
    } else {
      const createCommand = data as CreateVesselCommand
      // Set default country code if feature flag is disabled
      if (!vesselCountryCodeFF) createCommand.countryCode = 'DE'

      await createVessel({
        ...createCommand,
        countryCode: createCommand.countryCode.toUpperCase(),
      })
    }
  }

  const title = editMode ? t('editVessel', 'Edit Vessel') : t('createVessel', 'Create Vessel')

  return (
    <Container sx={{ width: '700px', padding: '1rem' }}>
      <Typography variant='h3'>{title}</Typography>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Stack direction='column' my={'1rem'} spacing={'1rem'}>
          <TextField
            fullWidth
            label={t('name', 'Name')}
            variant='outlined'
            {...register('name', { required: true })}
            name='name'
            defaultValue={vessel?.name || ''}
            error={!!errors.name}
            helperText={errors.name ? t('fieldIsRequired', 'Field is required.') : ''}
          />

          {isOrderNewCompanyFieldsActive ? (
            <Controller
              control={control}
              name='shippingLine'
              defaultValue={vessel?.shippingLine}
              render={({ field }) => (
                <CustomerAutoComplete
                  label={t('shippingLine', 'Shipping Line')}
                  dataCy='shipping-line-autocomplete'
                  selectedCompanyShortName={field.value}
                  handleOnChange={operator => {
                    field.onChange(operator?.shortName)
                  }}
                  filterByTypes={[CompanyType.VesselOperator]}
                />
              )}
            />
          ) : (
            <TextField
              fullWidth
              label={t('shippingLine', 'Shipping Line')}
              variant='outlined'
              {...register('shippingLine')}
              name='shippingLine'
              defaultValue={vessel?.shippingLine || ''}
            />
          )}

          <TextField
            fullWidth
            label={t('imoNumber', 'IMO Number')}
            variant='outlined'
            {...register('imoNumber', { required: true })}
            name='imoNumber'
            defaultValue={vessel?.imoNumber || ''}
            error={!!errors.imoNumber}
            helperText={errors.imoNumber ? t('fieldIsRequired', 'Field is required.') : ''}
          />
          <TextField
            fullWidth
            label={t('mmsi', 'MMSI')}
            variant='outlined'
            {...register('mmsi')}
            name='mmsi'
            defaultValue={vessel?.mmsi || ''}
          />
          <TextField
            fullWidth
            label={t('callSign', 'Call Sign')}
            variant='outlined'
            {...register('callsign')}
            name='callsign'
            defaultValue={vessel?.callSign || ''}
          />
          <TextField
            fullWidth
            label={t('width', 'Width')}
            variant='outlined'
            type='number'
            {...register('width', { required: true, valueAsNumber: true })}
            name='width'
            defaultValue={vessel?.width || ''}
            error={!!errors.width}
            helperText={errors.width ? t('fieldIsRequired', 'Field is required.') : ''}
            onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
            inputProps={{ min: 1 }}
          />
          <TextField
            fullWidth
            label={t('length', 'Length')}
            variant='outlined'
            type='number'
            {...register('length', { required: true, valueAsNumber: true })}
            name='length'
            defaultValue={vessel?.length || ''}
            error={!!errors.length}
            helperText={errors.length ? t('fieldIsRequired', 'Field is required.') : ''}
            onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
            inputProps={{ min: 1 }}
          />
          {vesselCountryCodeFF && (
            <Controller
              control={control}
              name='countryCode'
              defaultValue={vessel?.countryCode || ''}
              rules={{
                required: t('fieldIsRequired', 'Field is required.'),
                minLength: {
                  value: 2,
                  message: t('countryCodeLengthMustBeTwo', 'Country code length must be 2.'),
                },
              }}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  freeSolo
                  disablePortal
                  options={countryCodeOptions}
                  defaultValue={vessel?.countryCode || ''}
                  onChange={(_, value) => onChange(value)}
                  renderInput={params => (
                    <TextField
                      key={params.id}
                      {...params}
                      label={t('countryCode', 'Country code')}
                      error={!!errors.countryCode}
                      helperText={
                        errors.countryCode?.message ? errors.countryCode.message?.toString() : ''
                      }
                      onChange={e => onChange(e)}
                      InputProps={{
                        ...params.InputProps,
                        inputProps: {
                          ...params.inputProps,
                          maxLength: 2,
                          style: { textTransform: 'uppercase' },
                        },
                      }}
                    />
                  )}
                />
              )}
            />
          )}
        </Stack>
        <Button variant='contained' type='submit'>
          {t('submit', 'Submit')}
        </Button>
      </form>
    </Container>
  )
})
