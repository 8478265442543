import { Stack, Typography } from '@mui/material'
import { CarrierType, ContainerPositionType, JobDto, OperationType } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import {
  ArrowUpRightIcon,
  EmptyIcon,
  FullIcon,
  TrainIcon,
  TruckIcon,
  useMinimalsTheme,
  VesselIcon,
  WeightIcon,
} from '@tom-ui/ui'
import { getContainerHeight } from '../../utils'

export interface Props {
  job: JobDto
  showOutboundInfo: boolean
}
export const JobDescriptionMinimalistic = ({ job, showOutboundInfo }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  if (!job.cargoUnit) return <></>

  const isContainerJob =
    job.origin.type !== ContainerPositionType.TrailerParkingLot &&
    job.destination.type !== ContainerPositionType.TrailerParkingLot

  const getOutboundInfo = () => {
    const { outboundCarrierType, outboundCarrierName, outboundCarrierAta, outboundCarrierEta } =
      job.cargoUnit || {}
    if (!showOutboundInfo || job.operationType !== OperationType.Inbound || !outboundCarrierType)
      return

    let icon

    switch (outboundCarrierType) {
      case CarrierType.Vessel:
        icon = <VesselIcon />
        break
      case CarrierType.Train:
        icon = <TrainIcon />
        break
      case CarrierType.Truck:
        icon = <TruckIcon />
        break
    }

    const outboundName = outboundCarrierName ?? t(outboundCarrierType)
    const outboundXta = outboundCarrierAta ?? outboundCarrierEta

    return (
      <>
        <Stack flexDirection='row' alignItems='center' gap={theme.customSpacing.xs}>
          <Stack flexDirection='row' alignItems='center'>
            <ArrowUpRightIcon />
            {icon}
          </Stack>
          <Stack flexDirection='column' alignItems='center'>
            <Typography variant='body2' whiteSpace='nowrap'>
              {outboundName}
            </Typography>
            {outboundXta && (
              <Typography variant='body2' whiteSpace='nowrap' color='secondary'>
                {new Date(outboundXta).toLocaleDateString()}
              </Typography>
            )}
          </Stack>
        </Stack>
      </>
    )
  }

  return (
    <Stack flexDirection='row' gap={theme.customSpacing.l}>
      {isContainerJob && (
        <Stack flexDirection='row' alignItems='center' gap={theme.customSpacing.xs}>
          {job.cargoUnit?.isEmpty ? <EmptyIcon /> : <FullIcon />}
          {!!job.cargoUnit.length && !!job.cargoUnit.height && (
            <Typography variant='body2'>
              {job.cargoUnit.length} {getContainerHeight(job.cargoUnit.height)}
            </Typography>
          )}
        </Stack>
      )}
      {!!job.order?.grossWeight && (
        <Stack flexDirection='row' alignItems='center' gap={theme.customSpacing.xs}>
          <WeightIcon />
          <Typography variant='body2'>{`${job.order.grossWeight}kg`}</Typography>
        </Stack>
      )}
      {getOutboundInfo()}
    </Stack>
  )
}
