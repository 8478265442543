import { Autocomplete, TextField } from '@mui/material'
import { CompanyResponseDto, CompanyType } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { CustomerAutocompleteViewStore } from '@planning/stores/customer/CustomerAutocompleteViewStore'
import { useTranslate } from '@tolgee/react'
import { observer, useLocalStore } from 'mobx-react-lite'
import { FC, useEffect, useMemo } from 'react'

interface Props {
  selectedCustomer?: CompanyResponseDto
  handleOnChange?: (value?: CompanyResponseDto) => void
  error?: boolean
  helperText?: React.ReactNode
  label?: string
  disable?: boolean
  required?: boolean
  filterByTypes?: CompanyType[]
  selectedCompanyShortName?: string
  dataCy?: string
}

export const CustomerAutoComplete: FC<Props> = observer(
  ({
    selectedCustomer,
    handleOnChange,
    error,
    helperText,
    label,
    disable,
    required,
    filterByTypes,
    selectedCompanyShortName,
    dataCy,
  }) => {
    const { t } = useTranslate()
    const { appViewStore } = usePlanningStore()
    const customerAutocompleteViewStore = useLocalStore(() => new CustomerAutocompleteViewStore())

    useEffect(() => {
      const fetch = async () => {
        await customerAutocompleteViewStore.fetch(customerAutocompleteViewStore.filter)
      }

      fetch().catch(error => appViewStore.setShowAlert('error', error.message))
    }, [appViewStore, customerAutocompleteViewStore])

    const value = useMemo(() => {
      if (selectedCustomer) {
        return selectedCustomer
      }

      if (selectedCompanyShortName) {
        const company = customerAutocompleteViewStore.items.find(
          c => c.shortName === selectedCompanyShortName,
        )
        return company ? { ...company } : null
      }

      return null
    }, [selectedCustomer, selectedCompanyShortName, customerAutocompleteViewStore.items])

    const onSearchFilterChange = async (newFilter: string) => {
      customerAutocompleteViewStore.setFilter(newFilter)

      if (!newFilter.length) {
        await customerAutocompleteViewStore.fetch(newFilter)
      }
    }

    const handleOnClose = () => {
      customerAutocompleteViewStore.setOpen(false)
      if (!value) {
        onSearchFilterChange('')
      }
    }

    return (
      <Autocomplete
        disablePortal
        disabled={disable}
        getOptionLabel={customer => customer.name}
        open={customerAutocompleteViewStore.isOpen}
        onOpen={() => customerAutocompleteViewStore.setOpen(true)}
        onClose={handleOnClose}
        options={customerAutocompleteViewStore.items.filter(
          x => !filterByTypes?.length || x.types?.some(t => filterByTypes.includes(t)),
        )}
        isOptionEqualToValue={(option: CompanyResponseDto, value: CompanyResponseDto) =>
          option.id === value.id
        }
        value={value}
        onChange={(_, customer) => {
          if (handleOnChange) handleOnChange(customer ?? undefined)
        }}
        renderInput={params => (
          <TextField
            required={required}
            {...params}
            data-cy={dataCy ?? 'customer-autocomplete'}
            label={label ?? t('customer', 'Customer')}
            error={error}
            helperText={helperText}
            onChange={e => {
              onSearchFilterChange(e.target.value)
            }}
          />
        )}
      />
    )
  },
)
