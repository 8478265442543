import { ContainerType } from '@planning/app/api'
import { IContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourney'

export const canAssignCoolingOrder = (journey: IContainerJourney) => {
  const { stuffingOrderId, coolingOrderId, inboundOrder, container } = journey

  if (coolingOrderId || !inboundOrder?.order || container.type !== ContainerType.Reefer)
    return false

  if (inboundOrder.order.isEmpty && !stuffingOrderId) return false

  return true
}

export const canAssignStrippingOrder = (journey: IContainerJourney) => {
  const { strippingOrderId, inboundOrder } = journey
  return !strippingOrderId && inboundOrder?.order && !inboundOrder.order.isEmpty
}

export const canAssignStuffingOrder = (journey: IContainerJourney) => {
  const { stuffingOrderId, strippingOrderId, inboundOrder } = journey
  return !stuffingOrderId && inboundOrder?.order && (inboundOrder.order.isEmpty || strippingOrderId)
}
