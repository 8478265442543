import { CompanyDto, CompanyType } from '@billing/app/api'
import { FormProps, SelectOption } from '@billing/app/models'
import useFormWithSchema from '@billing/hooks/use-form-with-schema.hook'
import { Autocomplete, Box, FormGroup, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { BinIcon, IconButton, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import _ from 'lodash'
import { matchSorter } from 'match-sorter'
import { useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { CompanyFormMapper } from './company-form.mapper'
import { CompanyFormProfile, defaultValues } from './company-form.profile'
import { schema } from './company-form.scheme'

interface Props extends FormProps {
  company?: CompanyDto
  onSubmit: (formValues: CompanyFormProfile) => Promise<void> | void
}

export const CompanyForm = ({ id, company, onSubmit }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const [alternativeNames, setAlternativeNames] = useState<string[]>(
    company?.alternativeNames.length ? company.alternativeNames : [''],
  )

  const updateAlternativeNames = (newAlternativeNames: string[]) => {
    setAlternativeNames(newAlternativeNames)
    setValue('alternativeNames', newAlternativeNames)
  }

  const { handleSubmit, formState, control, setValue, reset } =
    useFormWithSchema<CompanyFormProfile>(schema(), defaultValues)

  const companyTypeOptions: SelectOption[] = useMemo(
    () =>
      _(
        Object.values(CompanyType).map(x => ({
          label: t(_.lowerFirst(x)),
          value: x,
        })),
      )
        .sortBy(i => i.label)
        .value(),
    [],
  )

  useEffect(() => {
    if (company) {
      reset(CompanyFormMapper.mapDtoToFormValues(company))
    }
  }, [reset, company])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      pb={theme.customSpacing.xs}
    >
      <FormGroup sx={{ gap: theme.customSpacing.l }}>
        <Typography variant='subtitle2'>{t('companyDetails', 'Company details')}</Typography>
        <Controller
          control={control}
          name='shortName'
          render={({ field }) => (
            <TextField
              {...field}
              error={!!formState.errors['shortName']}
              label={t('companyId', 'Company id')}
              disabled={!!company?.shortName}
              fullWidth
              helperText={
                formState.errors['shortName']
                  ? t('fieldIsRequired', 'Field is required')
                  : t('pleaseMakeSureItIsAUniqueId', 'Please make sure it is a unique id')
              }
            />
          )}
        />

        <Controller
          control={control}
          name='name'
          render={({ field }) => (
            <TextField
              {...field}
              error={!!formState.errors['name']}
              label={t('companyName', 'Company Name')}
              fullWidth
              helperText={
                formState.errors['name'] ? t('fieldIsRequired', 'Field is required') : undefined
              }
            />
          )}
        />

        {alternativeNames.map((alternativeName, index) => (
          <Stack
            key={`alternative_name_${index}`}
            flexDirection={'row'}
            gap={theme.customSpacing.s}
          >
            <TextField
              value={alternativeName}
              onChange={event => {
                const newAlternativeNames = [...alternativeNames]
                newAlternativeNames[index] = event.target.value
                updateAlternativeNames(newAlternativeNames)
              }}
              label={t('alternativeName', 'Alternative Name')}
              fullWidth
            />

            {index === 0 ? (
              <Tooltip title={t('add', 'Add')}>
                <IconButton
                  onClick={() => updateAlternativeNames((alternativeNames ?? []).concat(''))}
                >
                  <PlusIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t('remove', 'Remove')}>
                <IconButton
                  onClick={() =>
                    updateAlternativeNames(alternativeNames.filter((_, i) => i !== index))
                  }
                >
                  <BinIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        ))}

        <Controller
          control={control}
          name='companyTypes'
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={companyTypeOptions}
              value={companyTypeOptions.filter(x => field.value.includes(x.value.toString())) ?? []}
              onChange={(_, newValue: SelectOption[] | null) =>
                field.onChange(newValue?.map(x => x.value) ?? [])
              }
              getOptionLabel={option => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={params => (
                <TextField
                  {...params}
                  error={!!formState.errors['companyTypes']}
                  helperText={
                    formState.errors['companyTypes']
                      ? t('fieldIsRequired', 'Field is required')
                      : undefined
                  }
                  label={t('companyType', 'Company type')}
                />
              )}
              filterOptions={(options, { inputValue }) => {
                return matchSorter(options, inputValue.replace(/\s/g, ''), {
                  keys: [item => item.label.replace(/\./g, '').replace(/\s/g, '')],
                  threshold: matchSorter.rankings.CONTAINS,
                })
              }}
            />
          )}
        />

        <Controller
          control={control}
          name='taxId'
          render={({ field }) => (
            <TextField
              {...field}
              label={t('taxId', 'Tax Id')}
              fullWidth
              helperText={t('pleaseMakeSureItIsAUniqueId', 'Please make sure it is a unique id')}
            />
          )}
        />

        <Typography variant='subtitle2'>{t('contactData', 'Contact data')}</Typography>

        <Controller
          control={control}
          name='address'
          render={({ field }) => <TextField {...field} label={t('address', 'Address')} fullWidth />}
        />

        <Controller
          control={control}
          name='phoneNumber'
          render={({ field }) => (
            <TextField
              {...field}
              onChange={event => {
                field.onChange(event.target.value.replace(/[^\d+()-\s]/g, ''))
              }}
              label={t('phoneNumber', 'Phone number')}
              fullWidth
            />
          )}
        />

        <Controller
          control={control}
          name='email'
          render={({ field }) => (
            <TextField
              {...field}
              error={!!formState.errors['email']}
              label={t('email', 'Email')}
              helperText={
                formState.errors['email']
                  ? t('fieldShouldHaveEmailFormat', 'Field should have email format')
                  : undefined
              }
              fullWidth
            />
          )}
        />

        <Controller
          control={control}
          name='remarks'
          render={({ field }) => (
            <TextField {...field} multiline rows={3} label={t('notes', 'Notes')} fullWidth />
          )}
        />
      </FormGroup>
    </Box>
  )
}
