import { Button, Divider, Menu, MenuItem, Typography } from '@mui/material'
import { CarrierType, ContainerTurnoverDto } from '@storage/app/api'
import Damaged from '@storage/components/icons/svg/Damaged.svg'
import Dangerous from '@storage/components/icons/svg/Dangerous.svg'
import Empty from '@storage/components/icons/svg/Empty.svg'
import Full from '@storage/components/icons/svg/Full.svg'
import More from '@storage/components/icons/svg/More.svg'
import Reefer from '@storage/components/icons/svg/Reefer.svg'
import useContextMenu from '@storage/hooks/use-context-menu.hook'
import {
  DEFAULT_OCCUPANCY_BACKGROUND,
  EXPORT_OCCUPANCY_BACKGROUND,
  IMPORT_OCCUPANCY_BACKGROUND,
} from '@storage/pages/yard-management/constants'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { multipleStackingIndicator } from '@storage/utils/icons'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useMemo } from 'react'
import { Group, Image, Rect, Text } from 'react-konva'
import { Html } from 'react-konva-utils'
import useImage from 'use-image'
import { YardBlockBayFilterBy, YardBlockBayOverviewItem } from './models'

export interface YardBlockSlotProps {
  item: YardBlockBayOverviewItem
  left: number
  top: number
  slotWidth: number
  filterViewBy: YardBlockBayFilterBy
  onMenuAction?: (action: string, turnover: ContainerTurnoverDto) => void
  tenantConfigStore: TenantConfigStore
}

export const YardBlockBayOverviewSlotItem = ({
  item,
  left,
  top,
  slotWidth,
  onMenuAction,
  filterViewBy: viewExportBy,
  tenantConfigStore,
}: YardBlockSlotProps) => {
  const { palette } = useMinimalsTheme()
  const { menuProps, handleOpenMenu } = useContextMenu()
  const { t } = useTranslate()

  const [reefer] = useImage(Reefer)
  const [empty] = useImage(Empty)
  const [full] = useImage(Full)
  const [dangerous] = useImage(Dangerous)
  const [damaged] = useImage(Damaged)
  const [moreVertMenu] = useImage(More)

  const { width, height, padding, rowHeight, iconSize, iconBarSplit } = {
    width: slotWidth,
    height: slotWidth,
    padding: slotWidth / 30,
    rowHeight: slotWidth / 5,
    iconSize: (slotWidth / 6 / 32) * 32,
    iconBarSplit: slotWidth * 0.3,
  }

  const turnover = item.renderedTurnover
  const isReservation = item.reservations?.find(e => e.id === turnover?.id)
  const isOccupancy = item.occupancies?.find(e => e.id === turnover?.id)
  const isExport = turnover?.outboundCarrierType === CarrierType.Vessel
  const isImport = !isExport && turnover?.inboundCarrierType === CarrierType.Vessel
  const otherCarrierTypeThanVessel = !isExport && !isImport && isOccupancy
  const occupancyCount = item.occupancies?.length ?? 0

  const [multipleStackingImage] = useImage(multipleStackingIndicator(occupancyCount))

  const fillColor = useMemo(() => {
    if (turnover) {
      if (isOccupancy) {
        return isImport
          ? IMPORT_OCCUPANCY_BACKGROUND
          : isExport
            ? EXPORT_OCCUPANCY_BACKGROUND
            : DEFAULT_OCCUPANCY_BACKGROUND
      }
    }
    return DEFAULT_OCCUPANCY_BACKGROUND
  }, [turnover, isOccupancy, isImport, isExport])

  const strokeColor = useMemo(() => {
    if (isReservation) {
      return palette.info.main
    }
    if (otherCarrierTypeThanVessel) {
      return palette.grey[500]
    }
    if (!tenantConfigStore.skipYardPlanning && item.slot.isAllocated) {
      return palette.primary.main
    }
    return palette.grey[300]
  }, [
    isReservation,
    otherCarrierTypeThanVessel,
    tenantConfigStore.skipYardPlanning,
    item.slot.isAllocated,
    palette,
  ])

  const strokeWidth = useMemo(() => {
    if (otherCarrierTypeThanVessel) {
      return 2
    }
    if (!tenantConfigStore.skipYardPlanning && item.slot.isAllocated) {
      return 3
    }
    return 1
  }, [otherCarrierTypeThanVessel, tenantConfigStore.skipYardPlanning, item.slot.isAllocated])

  const opacity = useMemo(() => (isReservation ? 0.5 : 1), [isReservation])

  const textColor = useMemo(
    () => (isReservation ? palette.info.main : 'black'),
    [isReservation, palette.info.main],
  )

  const subtitle = useMemo(() => {
    if (turnover) {
      if (isImport) {
        if (viewExportBy === 'TripId') {
          return turnover.inboundTripIds?.length ? turnover.inboundTripIds[0] : ''
        } else {
          return turnover.inboundCarriers.map(x => x.name).join() ?? ''
        }
      } else {
        if (viewExportBy === 'TripId') {
          return turnover.outboundTripIds?.length ? turnover.outboundTripIds[0] : ''
        } else {
          return turnover.outboundCarriers.map(x => x.name).join() ?? ''
        }
      }
    }

    return ''
  }, [isImport, turnover, viewExportBy])

  const iconBar = (turnover: ContainerTurnoverDto) => {
    return (
      <Group x={0} y={height - padding - iconSize} opacity={opacity}>
        <Image
          image={turnover.isEmptyContainerCargoState ? empty : full}
          x={padding}
          width={iconSize}
          height={iconSize}
        />
        {turnover.isReefer && (
          <Image image={reefer} x={iconBarSplit} width={iconSize} height={iconSize} />
        )}
        {turnover.isDamaged && (
          <Image
            image={damaged}
            x={iconBarSplit + iconSize + padding}
            width={iconSize}
            height={iconSize}
          />
        )}
        {turnover.isDangerous && (
          <Image
            image={dangerous}
            x={iconBarSplit + 2 * (iconSize + padding)}
            width={iconSize}
            height={iconSize}
          />
        )}
        {isOccupancy && (
          <>
            <Image
              x={width - 15}
              rotation={0}
              width={iconSize}
              onClick={({ evt }) => handleOpenMenu(evt.clientX, evt.clientY)}
              image={moreVertMenu}
            />
            <Html>
              <Menu {...menuProps}>
                <MenuItem disabled onClick={e => e.stopPropagation()}>
                  <Typography variant='body2'>{t('updatePosition', 'Update Position')}</Typography>
                </MenuItem>
                <Divider />
                {item.occupancies?.map(occ => (
                  <MenuItem
                    key={occ.id}
                    style={{ fontSize: 12 }}
                    onClick={() => onMenuAction && onMenuAction('update', occ)}
                  >
                    <Button
                      size='small'
                      variant='contained'
                      onClick={() => onMenuAction && onMenuAction('update', occ)}
                    >
                      {occ.containerNumber}
                    </Button>
                  </MenuItem>
                ))}
              </Menu>
            </Html>
          </>
        )}
      </Group>
    )
  }

  return (
    <Group width={width} height={height} x={left} y={top} borderRadius='20px' overflow='hidden'>
      <Rect
        width={width}
        height={height}
        fill={fillColor}
        strokeWidth={strokeWidth}
        stroke={strokeColor}
        hitStrokeWidth={0}
      />
      {turnover && (
        <Group opacity={opacity}>
          <Text
            x={padding}
            y={padding}
            text={isImport ? turnover.portOfLoading ?? '' : turnover.portOfDischarge ?? ''}
            fontSize={10}
            fill={textColor}
            width={width / 2 - padding}
            ellipsis
            wrap='none'
          />
          {occupancyCount > 1 && (
            <Image
              image={multipleStackingImage}
              x={(width - iconSize) / 2 + padding}
              y={2}
              width={iconSize}
              height={iconSize}
            />
          )}
          <Text
            x={width / 2}
            y={padding}
            width={width / 2 - padding}
            align='right'
            text={turnover.containerOperator ?? ''}
            fontSize={10}
            fill={textColor}
            ellipsis
            wrap='none'
          />
          <Group x={padding} y={rowHeight} width={width - 2 * padding}>
            <Html>
              <Typography
                variant='subtitle1'
                sx={{
                  maxWidth: `${width - 2 * padding}px`,
                  textWrap: 'none',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: `${textColor} !important`,
                }}
              >
                {turnover.containerNumber}
              </Typography>
            </Html>
          </Group>
          <Text
            x={padding}
            y={2 * rowHeight}
            text={turnover.containerIsoCode ?? ''}
            fontSize={10}
            fill={textColor}
          />
          <Text
            x={-padding}
            y={2 * rowHeight}
            width={width}
            align='right'
            text={parseFloat(((turnover.grossWeight ?? 0) / 1000).toFixed(1)) + 't'}
            fontSize={10}
            fill={textColor}
          />
          <Text
            x={padding}
            y={3 * rowHeight}
            text={subtitle}
            fontSize={12}
            fill={textColor}
            width={width - 2 * padding}
            ellipsis
            wrap='none'
          />
          {iconBar(turnover)}
        </Group>
      )}
    </Group>
  )
}
