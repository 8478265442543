import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface IProps {
  formId: string
  saveTitle?: string
  cancelTitle?: string
  saveDisabled?: boolean
  onBack?: () => void
}

export function PageHeaderActions({
  formId,
  saveTitle,
  cancelTitle,
  saveDisabled,
  onBack,
}: IProps) {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const defaultSaveTitle = saveTitle ?? t('save', 'Save')
  const defaultCancelTitle = cancelTitle ?? t('cancel', 'Cancel')

  return (
    <Stack gap={theme.customSpacing.m} direction='row' alignItems='center'>
      <Button variant='text' onClick={onBack} color='inherit' data-cy='page-header-cancel-btn'>
        {defaultCancelTitle}
      </Button>

      <Button
        disabled={saveDisabled}
        variant='contained'
        color='primary'
        form={formId}
        data-cy='page-header-submit-btn'
        type='submit'
      >
        {defaultSaveTitle}
      </Button>
    </Stack>
  )
}
