import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection, IssueResponseDto } from '@planning/app/api'
import { ContainerHistoryList } from '@planning/components/ContainerHistoryList'
import { ContainerJourneyStatus } from '@planning/components/molecules/ContainerJourneyStatusChip'
import { OrderListItem } from '@planning/components/organisms/OrderListItem'
import { useOrderNavigation } from '@planning/hooks/useOrderNavigation'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { IssuesPageLocationState } from '@planning/pages/Issues'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import adminActionService from '@planning/services/adminActionService'
import { useTranslate } from '@tolgee/react'
import { ButtonOption, Group } from '@tom-ui/ui'
import { authStore } from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Id } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router'
import { ContainerYardOperationViewStore } from '../OrderGrouping'

export const OrderGroupListItem: FC<{
  group: Group
  item: { id: Id; content: IOrderItem }
  filter?: string
  hasThreeDotMenu?: boolean
  containerYardOperationViewStore: ContainerYardOperationViewStore
  onOrderHistoryFn?: (containerNumber?: string | null) => void
  onDeleteOrderFn?: (orders: IOrderItem[]) => void
}> = observer(
  ({
    group,
    item,
    filter,
    containerYardOperationViewStore,
    hasThreeDotMenu = false,
    onOrderHistoryFn,
    onDeleteOrderFn,
  }) => {
    const { t } = useTranslate()
    const navigate = useNavigate()

    const orderAdminContextMenuFf = useBooleanFlagValue('order-admin-context-menu', false)

    const { navigateCheckoutContainerPage, navigateCheckinContainerPage } = useOrderNavigation()

    const {
      appViewStore,
      dialogStore,
      containerEventViewStore,
      containerEventItemStore,
      issueItemStore,
    } = usePlanningStore()

    const onResolve = async (selectedItemId: number) => {
      navigate(`${PATH_PLANNING.issues}`, {
        state: { filterByRailCarTrackPositionId: selectedItemId } as IssuesPageLocationState,
      })
    }

    const { openEditOrder } = useOrderNavigation()

    const onDeleteOrder = async (orders: IOrderItem[]) => {
      if (onDeleteOrderFn) {
        return onDeleteOrderFn(orders)
      } else {
        const count = orders.length
        const confirmationMessageKey =
          count === 1 ? 'orderWillBeDeletedContinue' : 'ordersWillBeDeletedContinue'
        const confirmationMessage =
          count === 1
            ? 'Order will be deleted. Continue?'
            : `The selected ${count} Orders will be deleted. Continue?`

        const hasServiceOrders = orders.some(item => item.data.hasServiceOrders)

        const isConfirmed = await appViewStore.setOpenConfirmDialog(
          true,
          t(confirmationMessageKey, confirmationMessage),
          t('confirmOrderDelete', 'Confirm order delete'),
          hasServiceOrders
            ? t(
                'anyAssociatedStrippingOrStuffingOrdersWillBeDeleted',
                'Any associated stripping/stuffing orders will be deleted',
              )
            : undefined,
        )

        if (!isConfirmed) return

        try {
          await adminActionService.deleteByIds(orders.map(item => item.id))
          appViewStore.setShowAlert(
            'success',
            t('successfullyDeletedOrder', 'Successfully deleted order'),
          )
        } catch (e) {
          appViewStore.setShowAlert('error', t('failedToDeleteOrder', 'Failed to delete order'))
        }
      }
    }

    const onOrderHistory = async (containerNumber?: string | null) => {
      if (onOrderHistoryFn) {
        onOrderHistoryFn(containerNumber)
      } else {
        containerEventItemStore.setContainerNumber(containerNumber!)
        await containerEventItemStore.fetch()

        dialogStore.openDialog(
          <ContainerHistoryList
            containerNumber={containerNumber}
            store={containerEventViewStore}
          />,
        )
      }
    }

    const orderIssues = computed<IssueResponseDto[]>(() => {
      const orderIssues = issueItemStore.orderIssues.filter(
        i => item.content.data.notResolvedIssueIds?.includes(i.id) ?? false,
      )

      return orderIssues
    }).get()

    const getItemOptions = () => {
      if (!hasThreeDotMenu) return []

      const data = item.content.data
      const journey = item.content.containerJourney

      const actions: ButtonOption[] = [
        {
          label: t('viewDetails', 'View details'),
          onClick: () => openEditOrder(data),
        },
        {
          label: t('history', 'History'),
          onClick: () => onOrderHistory(data.containerNumber),
        },
      ]

      if (data.containerNumber || data.referenceNumber) {
        const copyActions: ButtonOption[] = []

        if (data.containerNumber) {
          copyActions.push({
            label: t('containerNumber', 'Container Number'),
            onClick: () => {
              const containerNumber = data.containerNumber
              if (data) navigator.clipboard.writeText(containerNumber!)
            },
          })
        }

        if (data.referenceNumber) {
          copyActions.push({
            label: t('referenceNumber', 'Reference Number'),
            onClick: () => {
              const referenceNumber = data.referenceNumber
              navigator.clipboard.writeText(referenceNumber!)
            },
          })
        }

        actions.push({
          label: t('copyDetail', 'Copy detail'),
          openToLeft: true,
          children: copyActions,
        })
      }

      if (orderIssues.length > 0 && data.railcarTrackPositionId) {
        actions.push({
          label: t('resolve', 'Resolve'),
          onClick: () => onResolve(data.railcarTrackPositionId!),
        })
      }

      const deleteAction: ButtonOption = {
        label: t('delete', 'Delete'),
        onClick: () => onDeleteOrder([item.content]),
      }

      let adminActions: ButtonOption[] = []

      //TODO Refactor when feature flag is disabled
      if (!orderAdminContextMenuFf) {
        adminActions = [deleteAction]
      }

      const isAdmin = authStore.isAdmin()

      if (isAdmin && orderAdminContextMenuFf) {
        adminActions = [
          {
            label: t('admin', 'Admin'),
            openToLeft: true,
            children: [
              {
                label: t('checkInContainer', 'Check in container'),
                disabled:
                  (journey?.status !== ContainerJourneyStatus.Expected &&
                    journey?.status !== ContainerJourneyStatus.Arrived) ||
                  data.direction === CarrierVisitDirection.Outbound,
                onClick: () => {
                  navigateCheckinContainerPage(data)
                },
              },
              {
                label: t('undoCheckIn', 'Undo check in'),
                disabled:
                  journey?.status === ContainerJourneyStatus.Expected ||
                  journey?.status === ContainerJourneyStatus.Arrived ||
                  journey?.status === ContainerJourneyStatus.Departed ||
                  data.direction === CarrierVisitDirection.Outbound,
                onClick: async () => {
                  const confirmed = await appViewStore.setOpenConfirmDialog(
                    true,
                    t(
                      'containerWillBeResetToExpected',
                      'Container no. {container} will be reset to Expected status and any associated jobs will be terminated. This will afect billing.',
                      { container: data.containerNumber },
                    ),
                    t('undoCheckin', 'Undo check in'),
                  )

                  if (confirmed) {
                    adminActionService.undoOrderCheckIn(data.id)
                  }
                },
              },
              {
                label: t('checkOutContainer', 'Check out container'),
                disabled:
                  journey?.status === ContainerJourneyStatus.Expected ||
                  journey?.status === ContainerJourneyStatus.Arrived ||
                  journey?.status === ContainerJourneyStatus.Departed ||
                  data.direction === CarrierVisitDirection.Inbound,
                onClick: () => {
                  navigateCheckoutContainerPage(data)
                },
              },
              {
                label: t('undoCheckOut', 'Undo check out'),
                disabled:
                  journey?.status !== ContainerJourneyStatus.Departed ||
                  data.direction === CarrierVisitDirection.Inbound,
                onClick: async () => {
                  if (!data.containerNumber) {
                    appViewStore.setShowAlert(
                      'error',
                      t('containerNumberIsMissing', 'Container number is missing'),
                    )
                  }

                  containerYardOperationViewStore!.open(
                    data.containerNumber!,
                    data.id,
                    t('undoCheckout', 'Undo check out'),
                    t(
                      'containerWillBeResetToTerminal',
                      'Container no. {container} will be reset to On terminal status. This will afect billing.',
                      { container: data.containerNumber },
                    ),
                    async () => await adminActionService.undoOrderCheckOut(data.id),
                  )
                },
              },
              {
                label: t('correctYardLocation', 'Correct yard location'),
                disabled: journey?.status !== ContainerJourneyStatus.OnYard,
                onClick: () => {
                  if (data.containerNumber) {
                    containerYardOperationViewStore.open(data.containerNumber, data.id)
                  } else {
                    appViewStore.setShowAlert(
                      'error',
                      t('containerNumberIsMissing', 'Container number is missing'),
                    )
                  }
                },
              },
              deleteAction,
            ],
          },
        ]
      }

      actions.push(...adminActions)

      return actions
    }

    return (
      <OrderListItem
        order={item.content.data}
        containerJourney={item.content.containerJourney}
        filter={filter}
        sx={{
          paddingLeft: group.sequenced ? 7.5 : 1.5,
        }}
        options={getItemOptions()}
      />
    )
  },
)
