enum Permission {
  ReadVisits = 'read:visits',
  ReadWorkAreas = 'read:work-areas',
  ReadEquipments = 'read:equipments',
  WriteEquipments = 'write:equipments',
  EquipmentsAssignment = 'equipments-assignment',
  ReadJobs = 'read:jobs',
  StartFinishJobs = 'start-finish-jobs',
  ReadWorkInstructions = 'read:work-instructions',
  ConfirmWorkInstructions = 'confirm-work-instructions',
  ReadReeferTemperatures = 'read:reefer-temperatures',

  // Planning Permissions
  ReadVessels = 'read:vessels',
  WriteOrders = 'write:orders',
  ReadOrders = 'read:orders',
  ReadRailVisits = 'read:rail-visits',
  WriteRailVisits = 'write:rail-visits',
  ReadRailCars = 'read:rail-cars',
  // End Planning Permissions

  // Admin Permissions
  ReadUsers = 'read:users',
  ReadAdminTenant = 'read:admin-tenant',
  ReadTenants = 'read:tenants',
  ReadDamageCatalogue = 'read:damage-catalogue',
  WriteDamageCatalogue = 'write:damage-catalogue',
  // End Admin Permissions

  // Storage Permissions
  ReadYardBlocks = 'read:yard-blocks',
  WriteYardBlocks = 'write:yard-blocks',

  ReadPiers = 'read:piers',
  WritePiers = 'write:piers',

  ReadBerths = 'read:berths',
  WriteBerths = 'write:berths',

  ReadRules = 'read:rules',
  WriteRules = 'write:rules',

  WriteSuperuser = 'write:superuser',
  ReadSuperuser = 'read:superuser',

  ReadRailTracks = 'read:rail-tracks',
  WriteRailTracks = 'write:rail-tracks',

  ReadWeightClasses = 'read:weight-classes',

  ReadDangerousGoodsSettings = 'read:dangerous-goods-settings',

  ReadYardPlanning = 'read:yard-planning',

  ResetData = 'reset-data',

  // Billing Service Permissions
  ReadBillableReport = 'read:billable_report',
  ReadCompanies = 'read:companies',
  WriteCompanies = 'write:companies',
  ReadCommodities = 'read:commodities',
  WriteCommodities = 'write:commodities',
  // End Billing Service Permissions
}

export default Permission
