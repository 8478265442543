import { Stack } from '@mui/material'
import { CustomsResponseDto, CustomsStatus } from '@planning/app/api'
import { AddIconButton } from '@planning/components/atoms/AddIconButton'
import { DeleteIconButton } from '@planning/components/atoms/DeleteIconButton'
import { NonPrimaryButton } from '@planning/components/atoms/NonPrimaryButton'
import { MultiselectActionBar } from '@planning/components/molecules/MultiselectActionBar'
import { SectionHeader } from '@planning/components/molecules/SectionHeader'
import { CustomsItem } from '@planning/components/organisms/CustomsSubForm/molecules/CustomsItem'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { IFormStore, useRegisterSubFormStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { CustomsSubFormStore } from './CustomsSubFormStore'
import { CustomsError, EmptyCustoms } from './molecules/EmptyCustoms'

export type CustomsSubFormProps<T, K extends keyof T> = {
  formStore: IFormStore<T>
  name: K
  allowDelete?: boolean
  showHeader?: boolean
  initialValue?: CustomsResponseDto[]
  allowCreation?: boolean
  allowSelection?: boolean
  disableReference?: boolean
  disableStatus?: boolean
  disableReason?: boolean
}

export const CustomsSubForm = observer(
  <T, K extends keyof T>({
    formStore,
    name,
    allowDelete,
    showHeader,
    initialValue,
    allowCreation = true,
    allowSelection = true,
    disableReference,
    disableStatus,
    disableReason,
  }: CustomsSubFormProps<T, K>) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const store = useRegisterSubFormStore(
      formStore,
      name,
      new CustomsSubFormStore(initialValue ?? []),
    )

    const {
      items,
      addCustoms,
      deleteCustoms,
      setSelectAll,
      setSelectItem,
      updateSelectedCustoms,
      deleteSelectedCustoms,
      selectAllState,
    } = store

    const actions = [
      <NonPrimaryButton
        key={CustomsStatus.Pending}
        text={t('pending', 'Pending')}
        onClick={() => updateSelectedCustoms(CustomsStatus.Pending)}
        size='small'
      />,
      <NonPrimaryButton
        key={CustomsStatus.InspectionRequired}
        text={t('inspectionRequired', 'Inspection required')}
        onClick={() => updateSelectedCustoms(CustomsStatus.InspectionRequired)}
        size='small'
      />,
      <NonPrimaryButton
        key={CustomsStatus.Cleared}
        text={t('cleared', 'Cleared')}
        onClick={() => updateSelectedCustoms(CustomsStatus.Cleared)}
        size='small'
      />,
      <NonPrimaryButton
        key={CustomsStatus.Refused}
        text={t('refused', 'Refused')}
        onClick={() => updateSelectedCustoms(CustomsStatus.Refused)}
        size='small'
      />,
    ]

    const secondaryActions = [
      <DeleteIconButton key={'deleted'} onClick={() => deleteSelectedCustoms()} />,
    ]

    const headerActions = [
      <AddIconButton
        key={'add'}
        onClick={() => addCustoms({ reference: '', status: CustomsStatus.Pending })}
      />,
    ]
    return (
      <Stack gap={theme.customSpacing.xs}>
        {showHeader && (
          <SectionHeader
            label={t('customsReferenceAndStatus', 'Customs references & status')}
            actions={allowCreation ? headerActions : undefined}
          />
        )}
        {items.value.length ? (
          <MultiselectActionBar
            state={selectAllState}
            handleOnStateChange={(checked: boolean) => setSelectAll(checked)}
            label={t('setTo', 'Set to')}
            actions={actions}
            secondaryActions={allowDelete ? secondaryActions : undefined}
            allowSelection={allowSelection}
          />
        ) : (
          <EmptyCustoms />
        )}
        {items.value.map((customs, index) => (
          <CustomsItem
            key={index}
            onChangeState={(checked: boolean) => setSelectItem(index, checked)}
            customs={customs}
            onDelete={allowDelete ? () => deleteCustoms(index) : undefined}
            allowSelection={allowSelection}
            disableReference={disableReference}
            disableStatus={disableStatus}
            disableReason={disableReason}
          />
        ))}
        {items.error && <CustomsError customsError={items.error} />}
      </Stack>
    )
  },
)
