import { Box, Button } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerDto, OrderStatus, UpsertContainerCommand } from '@planning/app/api'
import { SearchAutocomplete } from '@planning/components/list/SearchAutocomplete'
import { SimpleList } from '@planning/components/list/SimpleList'
import { IContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourney'
import { ContainerJourneyDataStore } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { CustomAlert } from '@planning/pages/TallymanV2/Components'
import { ContainerYardOperationViewStore } from '@planning/pages/VesselVisit/Components'
import { ISearchAutocompleteStore } from '@planning/stores/SearchAutocompleteStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { CreateOrdersPage } from '../CreateOrders'
import { EditOrdersPage } from '../EditOrders'
import { IAmAContainerVisitToo, SelectOrderViewStore } from '../stores/SelectOrderViewStore'
import { AddUnitInfoForm } from './AddUnitInfoForm'
import { ContainerAutocompleteListItem } from './ContainerAutocompleteListItem'
import { ContainerAutocompleteListItemLoader } from './ContainerAutocompleteListItemLoader'
import { ContainerCard } from './ContainerCard'
import { ContainerVisitPaperLoader } from './ContainerVisitPaperLoader'
import { ContainerVisitsCard } from './ContainerVisitsCard/ContainerVisitsCard'

interface IProps {
  searchStore: ISearchAutocompleteStore<ContainerDto>
  listStore: ContainerJourneyDataStore
  viewStore: SelectOrderViewStore
  onCreateNewOrderClick?: () => void
  onNewContainerSubmit?: (data: UpsertContainerCommand) => Promise<void>
  onReset: () => void
  showHavingOpenOrdersWarning?: boolean
  orderCreationOnly?: boolean
  containerYardOperationViewStore?: ContainerYardOperationViewStore
}

interface IGroupedContainerJourney {
  containerId: number
  linkedContainerJourneys: IContainerJourney[]
}

const getLeadingJourney = (groupedContainerJourney: IGroupedContainerJourney) => {
  let leadingJourney = groupedContainerJourney.linkedContainerJourneys[0]

  const onTerminalJourney = groupedContainerJourney.linkedContainerJourneys.find(
    lcj =>
      lcj.outboundOrder?.order?.status === OrderStatus.Open &&
      lcj.inboundOrder?.order?.status === OrderStatus.Fulfilled,
  )

  const expectedJourney = groupedContainerJourney.linkedContainerJourneys.find(
    lcj =>
      lcj.inboundOrder?.order?.status === OrderStatus.Open ||
      (lcj.outboundOrder?.order?.status === OrderStatus.Open && !lcj.inboundOrder),
  )

  if (onTerminalJourney) leadingJourney = onTerminalJourney
  else if (expectedJourney) leadingJourney = expectedJourney

  return leadingJourney
}

const getLinkedContainerJourneys = (
  groupedContainerJourney: IGroupedContainerJourney,
  leadingJourney: IContainerJourney,
) => {
  return groupedContainerJourney.linkedContainerJourneys.filter(lcj => lcj.id !== leadingJourney.id)
}

export const SearchContainerJourneyVisit = observer(
  ({
    searchStore,
    listStore,
    viewStore,
    onCreateNewOrderClick,
    onNewContainerSubmit,
    onReset,
    showHavingOpenOrdersWarning,
    containerYardOperationViewStore,
  }: IProps) => {
    const { dialogStore } = usePlanningStore()

    const { t } = useTranslate()

    const [showContainerForm, setShowContainerForm] = useState(false)

    const renderAutocompleteOption = (option: ContainerDto) => {
      return <ContainerAutocompleteListItem container={option} />
    }

    const CreateNewOrderButton = () => (
      <Button
        variant='contained'
        onClick={onCreateNewOrderClick}
        sx={{ mt: '1rem' }}
        data-cy='create-new-order'
      >
        {t('createNewOrder', 'Create new order')}
      </Button>
    )

    const CreateOrderFromNewContainer = () => (
      <Box sx={{ mt: '1rem' }}>
        <Button onClick={() => setShowContainerForm(true)}>
          {t('createNewContainer', 'Create new container')}
        </Button>
        {onNewContainerSubmit && showContainerForm && (
          <AddUnitInfoForm containerNumber={searchStore.filter} onSubmit={onNewContainerSubmit} />
        )}
      </Box>
    )

    const RenderEmptyOrders = () => {
      const onCreateOrder = (container?: ContainerDto) => {
        viewStore.selectContainer(container)
        dialogStore.openDialog(
          <CreateOrdersPage
            prefilledUnitNumber={container?.number ?? undefined}
            skipFindUnitStep
            onSubmit={() => {
              onReset()
            }}
          />,
        )
      }

      return (
        <>
          {viewStore.selectedContainer && (
            <ContainerCard container={viewStore.selectedContainer} onCreateOrder={onCreateOrder} />
          )}
          <Box sx={{ mt: '1rem' }}>
            {!!(searchStore.filter && viewStore.selectedContainerId) && (
              <CustomAlert
                severity='info'
                message={t('noOpenOrdersForThisContainer', 'No open orders for this container')}
              ></CustomAlert>
            )}
            {onCreateNewOrderClick && <CreateNewOrderButton />}
          </Box>
        </>
      )
    }

    const convertContainerVisit = (containerVisit: IContainerJourney): IAmAContainerVisitToo => {
      const inbound = containerVisit.inboundOrder
      const outbound = containerVisit.outboundOrder

      return [
        {
          order: inbound?.order,
          visit: inbound?.visit,
        },
        {
          order: outbound?.order,
          visit: outbound?.visit,
        },
      ]
    }

    const onEditOrder = (containerVisit: IContainerJourney) => {
      viewStore.selectContainerVisit(convertContainerVisit(containerVisit))
      viewStore.setContainerId(containerVisit.container.id)
      dialogStore.openDialog(<EditOrdersPage containerJourney={containerVisit} />)
    }

    return (
      <>
        <SearchAutocomplete
          label={t('searchContainerNumber', 'Search container number')}
          store={searchStore}
          renderOption={renderAutocompleteOption}
          renderLoadingOption={() => <ContainerAutocompleteListItemLoader />}
          getOptionLabel={c => c.number ?? ''}
          onChange={async (filter, value) => {
            if (value && typeof value != 'string' && value.id) {
              listStore.fetch({
                containerId: value.id,
                activeOrderIds: value.activeOrderIds,
                includeCompleted: true,
                includeUnlinkedOutboundOrders: true,
              })
              viewStore.selectContainer(value)
            }
          }}
          testId='container-journey-visit'
        />

        {/* TODO: maybe refactor and improve this one */}
        {onNewContainerSubmit && searchStore.filter && !searchStore.items.length ? (
          <CreateOrderFromNewContainer />
        ) : (
          <>
            {showHavingOpenOrdersWarning && !!listStore.items.length && (
              <Box sx={{ mt: '1rem' }}>
                <CustomAlert
                  severity='warning'
                  message={`${viewStore.selectedContainer?.number} ${t(
                    'hasTheFollowingOpenOrders',
                    'has the following open orders',
                  )}`}
                ></CustomAlert>
              </Box>
            )}
            <SimpleList
              store={listStore}
              listItems={listStore.groupedContainerJourneys}
              renderListItem={(gcj: any, i: number) => {
                const leadingJourney = getLeadingJourney(gcj)
                const remainingJourneys = getLinkedContainerJourneys(gcj, leadingJourney)

                return (
                  <ContainerVisitsCard
                    containerYardOperationViewStore={containerYardOperationViewStore}
                    containerVisit={leadingJourney}
                    announcedVisits={remainingJourneys}
                    key={`container-visit-paper-${i}`}
                    onClickEdit={(journey: IContainerJourney) => onEditOrder(journey)}
                  />
                )
              }}
              renderLoadingListItem={() => <ContainerVisitPaperLoader />}
              renderEmpty={() => RenderEmptyOrders()}
              showTotalResults={false}
            />
            {onCreateNewOrderClick && !!listStore.items.length && <CreateNewOrderButton />}
          </>
        )}
      </>
    )
  },
)
