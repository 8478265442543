import { OOG, OOGPosition, OrderResponseDto, UnitListDto } from '@planning/app/api'

export const mapToUnitListDto = (toBeMapped: OrderResponseDto): UnitListDto => {
  const oog: OOG[] = []
  if (toBeMapped.overHeight) {
    oog.push({ direction: OOGPosition.Top, measurement: toBeMapped.overHeight })
  }

  if (toBeMapped.overLengthFront) {
    oog.push({ direction: OOGPosition.Front, measurement: toBeMapped.overLengthFront })
  }

  if (toBeMapped.overLengthRear) {
    oog.push({ direction: OOGPosition.Rear, measurement: toBeMapped.overLengthRear })
  }

  if (toBeMapped.overWidthLeft) {
    oog.push({ direction: OOGPosition.Left, measurement: toBeMapped.overWidthLeft })
  }

  if (toBeMapped.overWidthRight) {
    oog.push({ direction: OOGPosition.Right, measurement: toBeMapped.overWidthRight })
  }

  return {
    id: toBeMapped.id,
    containerNumber: toBeMapped.containerNumber ?? '',
    isoCode: toBeMapped.containerIsoCode,
    referenceNumber: toBeMapped.referenceNumber,
    imoClasses: toBeMapped.imoClasses,
    grossWeight: toBeMapped.grossWeight,
    isEmpty: toBeMapped.isEmpty,
    linkedOrderId: toBeMapped.linkedOrderId,
    operator: toBeMapped.operator,
    portOfDischarge: toBeMapped.portOfDischarge,
    portOfLoading: toBeMapped.portOfLoading,
    temperature: toBeMapped.temperature,
    railTrackId: toBeMapped.railTrackId,
    waggon: toBeMapped.waggon,
    vgm: toBeMapped.vgm,
    unitType: toBeMapped.unitType,
    outOfGauge: oog,
  }
}
