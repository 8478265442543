import { Done } from '@mui/icons-material'
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CompanyType } from '@planning/app/api'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { CustomerAutoComplete } from '@planning/components/customer/CustomerAutoComplete'
import { containerHeights, containerTypes } from '@planning/constants'
import { useTranslate } from '@tolgee/react'
import { FieldBox } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ContainerJourneyNonNumericFilter } from '../Models/NonNumeric.model'

const lengthOptions = [
  { value: '20', label: '20 feet' },
  { value: '40', label: '40 feet' },
  { value: '45', label: '45 feet' },
]
const ownerOptions = ['Owner']

interface IProps {
  containerJourneyTotal?: number
  onFormDataChange: (data: ContainerJourneyNonNumericFilter) => void
  onClickChooseContainer: () => void
}

export const NonNumericForm = observer(
  ({ containerJourneyTotal, onFormDataChange, onClickChooseContainer }: IProps) => {
    const { t } = useTranslate()
    const { control, handleSubmit, reset, watch } = useForm({
      defaultValues: {
        length: '',
        height: '',
        type: '',
        operator: '',
        isoCode: '',
      },
    })
    const isOrderNewCompanyFieldsActive = useBooleanFlagValue(
      'order-changing-company-fields',
      false,
    )

    const [selectedOption, setSelectedOption] = useState('containerAttributes')
    useEffect(() => {
      const subscription = watch(data => {
        if (data.height || data.isoCode || data.length || data.operator || data.type)
          onFormDataChange(data as ContainerJourneyNonNumericFilter)
      })

      return () => subscription.unsubscribe()
    }, [watch, onFormDataChange])

    const onSubmit = (data: any) => {
      onFormDataChange(data)
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mb={6}>
          <Grid item xs={12}>
            <Stack direction='row' spacing={1}>
              <Chip
                icon={selectedOption === 'containerAttributes' ? <Done /> : <></>}
                variant={selectedOption === 'containerAttributes' ? 'filled' : 'outlined'}
                label={t('containerAttributes', 'Container attributes')}
                onClick={() => {
                  setSelectedOption('containerAttributes')
                  reset()
                }}
              />

              <Chip
                icon={selectedOption === 'isoCode' ? <Done /> : <></>}
                label={t('isoCode', 'Iso Code')}
                variant={selectedOption === 'isoCode' ? 'filled' : 'outlined'}
                onClick={() => {
                  setSelectedOption('isoCode')
                  reset()
                }}
              />
            </Stack>
          </Grid>

          {selectedOption === 'containerAttributes' && (
            <>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>{t('length', 'Length')}</InputLabel>
                  <Controller
                    name='length'
                    control={control}
                    render={({ field }) => (
                      <Select {...field} label='Length'>
                        {lengthOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>{t('height', 'Height')}</InputLabel>
                  <Controller
                    name='height'
                    control={control}
                    render={({ field }) => (
                      <Select {...field} label='Height'>
                        {containerHeights.map(d => {
                          return (
                            <MenuItem key={d.key} value={d.name}>
                              {d.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{t('type', 'Type')}</InputLabel>
                  <Controller
                    name='type'
                    control={control}
                    render={({ field }) => (
                      <Select {...field} label='Type'>
                        {containerTypes
                          .filter(t => t.name !== 'Unknown')
                          .map(d => {
                            return (
                              <MenuItem key={d.key} value={d.name}>
                                {getContainerTypeParameters(d.name, t)['label']}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name='operator'
                    control={control}
                    render={({ field }) =>
                      isOrderNewCompanyFieldsActive ? (
                        <CustomerAutoComplete
                          label={t('operator', 'Operator')}
                          selectedCompanyShortName={field.value}
                          handleOnChange={operator => {
                            field.onChange(operator?.shortName)
                          }}
                          filterByTypes={[CompanyType.ShippingLine]}
                        />
                      ) : (
                        <TextField
                          {...field}
                          label={t('operator', 'Operator')}
                          variant='outlined'
                        />
                      )
                    }
                  />
                </FormControl>
              </Grid>
            </>
          )}

          {selectedOption === 'isoCode' && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name='isoCode'
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} label={t('isoCode', 'ISO Code')} variant='outlined' />
                  )}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FieldBox
                label={`${containerJourneyTotal ?? 0} ${t('containersAvailable', 'Containers available')}`}
                rightRender={
                  <Button onClick={onClickChooseContainer}>
                    {t('chooseContainer', 'Choose container')}
                  </Button>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    )
  },
)
