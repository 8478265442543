import { Typography } from '@mui/material'
import { JobDto, OperationType, OperatorFields } from '@operations/app/api'
import { getContainerHeight } from '@operations/features/equipmentOperator/utils'
import { convertOperationType, lowerFirstCase } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { Tile } from '@tom-ui/ui'
import { DescriptionContainer } from './DescriptionContainer'

interface Props {
  job: JobDto
  stepper?: boolean
  fieldsToShow?: OperatorFields
}
export const JobDescription = ({ job, stepper, fieldsToShow }: Props) => {
  const { t } = useTranslate()

  const checkFieldsToShow = (operatorField: OperatorFields) => {
    return !fieldsToShow || fieldsToShow.includes(operatorField)
  }

  const dashIfNull = (value?: string | null) => value ?? ' - '

  const getOutboundInfo = () => {
    const { outboundCarrierType, outboundCarrierName, outboundCarrierAta, outboundCarrierEta } =
      job.cargoUnit || {}
    if (!outboundCarrierType) return

    const outboundName = outboundCarrierName ?? t(outboundCarrierType)
    const outboundXta = outboundCarrierAta ?? outboundCarrierEta

    return (
      <>
        <Typography variant='subtitle2' whiteSpace='nowrap'>
          {outboundName}
        </Typography>
        {outboundXta && (
          <Typography variant='subtitle2' whiteSpace='nowrap'>
            {new Date(outboundXta).toLocaleDateString()}
          </Typography>
        )}
      </>
    )
  }

  return (
    <DescriptionContainer isForStepper={stepper}>
      {checkFieldsToShow(OperatorFields.Process) && (
        <Tile
          nowrap
          label={t('process', 'Process')}
          value={t(convertOperationType(job.operationType))}
        />
      )}

      {job.operationType === OperationType.Service && job.serviceOrder?.type && (
        <Tile nowrap label={t('service', 'Service')} value={t(job.serviceOrder.type)} />
      )}

      {checkFieldsToShow(OperatorFields.ReferenceNumber) && (
        <Tile
          nowrap
          label={t('referenceNumber', 'Reference number')}
          value={dashIfNull(job.order?.referenceNumber)}
        />
      )}

      {checkFieldsToShow(OperatorFields.Sequence) &&
        job.grouping?.sequence &&
        job.grouping?.groupTotal && (
          <Tile
            nowrap
            label={t('sequence', 'Sequence')}
            value={
              dashIfNull(job.grouping.sequence?.toString()) +
              ' / ' +
              dashIfNull(job.grouping.groupTotal?.toString())
            }
          />
        )}

      {checkFieldsToShow(OperatorFields.State) && (
        <Tile
          nowrap
          label={t('state', 'State')}
          value={job.cargoUnit?.isEmpty ? t('empty', 'Empty') : t('full', 'Full')}
        />
      )}

      {checkFieldsToShow(OperatorFields.ImoClasses) &&
        job.order?.isDangerous &&
        job.order?.imoClasses?.length > 0 && (
          <Tile
            nowrap
            label={t('imoClasses', 'IMO classes')}
            value={job.order.imoClasses.join(', ')}
          />
        )}

      {checkFieldsToShow(OperatorFields.Weight) &&
        !job.cargoUnit?.isEmpty &&
        !!job.order?.grossWeight && (
          <Tile nowrap label={t('weight', 'Weight')} value={`${job.order.grossWeight} kg`} />
        )}

      {checkFieldsToShow(OperatorFields.Iso) && job.cargoUnit?.containerIsoCode && (
        <Tile nowrap label={t('iso', 'Iso')} value={job.cargoUnit.containerIsoCode} />
      )}

      {checkFieldsToShow(OperatorFields.Length) && !!job.cargoUnit?.length && (
        <Tile nowrap label={t('length', 'Length')} value={job.cargoUnit.length + 'ft'} />
      )}

      {checkFieldsToShow(OperatorFields.Height) && !!job.cargoUnit?.height && (
        <Tile nowrap label={t('high', 'High')} value={getContainerHeight(job.cargoUnit.height)} />
      )}

      {checkFieldsToShow(OperatorFields.Type) && (
        <Tile
          nowrap
          label={t('type', 'Type')}
          value={t(lowerFirstCase(job.cargoUnit?.type ?? ''))}
        />
      )}

      {checkFieldsToShow(OperatorFields.Carrier) &&
        job.operationType !== OperationType.Internal &&
        job.carrierVisit?.carrierName && (
          <Tile
            nowrap
            label={t('carrier', 'Carrier')}
            value={job.carrierVisit.carrierName}
            dataCy='job-card-carrier'
          />
        )}

      {checkFieldsToShow(OperatorFields.Operator) && job.cargoUnit?.operator && (
        <Tile nowrap label={t('operator', 'Operator')} value={job.cargoUnit.operator} />
      )}

      {checkFieldsToShow(OperatorFields.Pod) && job.cargoUnit?.portOfDischarge && (
        <Tile nowrap label={t('pod', 'POD')} value={job.cargoUnit.portOfDischarge} />
      )}

      {checkFieldsToShow(OperatorFields.Consignee) && job.order?.consignee && (
        <Tile nowrap label={t('consignee', 'Consignee')} value={job.order.consignee} />
      )}

      {checkFieldsToShow(OperatorFields.TransportMode) && job.cargoUnit?.outboundCarrierType && (
        <Tile label={t('transportOut', 'Transport out')}>{getOutboundInfo()} </Tile>
      )}

      {job.requestedTemperature !== null && job.requestedTemperature !== undefined && (
        <Tile
          nowrap
          label={t('requestedTemperature', 'Requested temperature')}
          value={job.requestedTemperature + '°C'}
        />
      )}

      {job.order?.isOOG && (
        <Tile
          nowrap
          label={t('handlingType', 'Handling type')}
          value={t('oog', 'OOG')}
          dataCy='job-card-oog'
        />
      )}
    </DescriptionContainer>
  )
}
