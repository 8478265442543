import { ContainerItemStore } from '@tom-ui/planning/rt-stores/container/ContainerItemStore'
import { OrderItemStore } from '@tom-ui/planning/rt-stores/order/OrderItemStore'
import { action, computed, makeObservable, observable } from 'mobx'

export class CheckinContainerViewStore {
  orderId?: number | null

  hasSelectedDestination = false

  isLoading = false

  constructor(
    private containerItemStore: ContainerItemStore,
    private orderItemStore: OrderItemStore,
  ) {
    makeObservable(this, {
      orderId: observable,
      hasSelectedDestination: observable,
      isLoading: observable,

      order: computed,
      container: computed,

      setLoading: action,
      setOrderId: action,
    })
  }

  get order() {
    if (this.orderId) {
      return this.orderItemStore.elements[this.orderId]?.data
    }
  }

  get container() {
    if (this.order?.containerId) {
      return this.containerItemStore.elements[this.order.containerId]?.data
    }
  }

  setOrderId = (orderId: number | null) => (this.orderId = orderId)

  setLoading = (isLoading: boolean) => (this.isLoading = isLoading)

  setHasSelectedDestination = (hasSelectedDestination: boolean) =>
    (this.hasSelectedDestination = hasSelectedDestination)
}
