import { CarrierType } from '@planning/app/api'
import { ContainerJourneyItemStore } from '@planning/pages/Issues/Stores/ContainerJourneyItemStore'
import { IssueItemStore } from '@planning/pages/Issues/Stores/IssueItemStore'
import _ from 'lodash'
import { computed, makeObservable, reaction } from 'mobx'
import { BerthItemStore } from './berth/BerthItemStore'
import { ContainerItemStore } from './container/ContainerItemStore'
import { NnrOrderItemStore } from './nnrOrder/NnrOrderItemStore'
import { OrderItemStore } from './order/OrderItemStore'
import { OrderUpdateItemStore } from './orderUpdate/OrderUpdateItemStore'
import { RailcarTrackPositionItemStore } from './railTrack/RailcarTrackPositionItemStore'
import { RailTrackItemStore } from './railTrack/RailTrackItemStore'
import { RailVisitItemStore } from './railVisit/RailVisitItemStore'
import { TruckItemStore } from './truck/TruckItemStore'
import { TruckVisitItemStore } from './truckVisit/TruckVisitItemStore'
import { VesselItemStore } from './vessel/VesselItemStore'
import { VesselVisitItemStore } from './vesselVisit/VesselVisitItemStore'

export class DataManager {
  constructor(
    private vesselVisitStore: VesselVisitItemStore,
    private railVisitStore: RailVisitItemStore,
    private vesselStore: VesselItemStore,
    private berthStore: BerthItemStore,
    private railTrackStore: RailTrackItemStore,
    private railcarTrackPositionStore: RailcarTrackPositionItemStore,
    private orderStore: OrderItemStore,
    private truckVisitStore: TruckVisitItemStore,
    private truckStore: TruckItemStore,
    private containerStore: ContainerItemStore,
    private nnrOrderStore: NnrOrderItemStore,
    private issueStore: IssueItemStore,
    private orderUpdateItemStore: OrderUpdateItemStore,
    private containerJourneyItemStore: ContainerJourneyItemStore,
  ) {
    makeObservable(this, {
      requestedVesselVisitIds: computed,
      requestedRailVisitIds: computed,
      requestedTruckVisitIds: computed,
      requestedVesselIds: computed,
      requestedTruckIds: computed,
      requestedOrderIds: computed,
      requestedNnrOrderIds: computed,
      requestedNnrOrderIdsFromOrders: computed,
      requestedIssueIds: computed,
    })

    reaction(() => this.requestedVesselVisitIds, this.fetchOrdersByVisits)
    reaction(() => this.requestedVesselVisitIds, this.fetchBerths)
    reaction(() => this.requestedVesselVisitIds, this.fetchContainersByVisits)
    reaction(() => this.requestedVesselVisitIds, this.fetchNnrOrdersByVisits)
    reaction(() => this.requestedRailVisitIds, this.fetchOrdersByVisits)
    reaction(() => this.requestedRailVisitIds, this.fetchContainersByVisits)
    reaction(() => this.requestedRailVisitIds, this.fetchRailTracks)
    reaction(() => this.requestedRailVisitIds, this.fetchNnrOrdersByVisits)
    reaction(
      () => this.requestedRailcarTrackPositionsFromRailVisits,
      this.fetchRailcarTrackPositionsByIds,
    )
    reaction(() => this.requestedTruckVisitIds, this.fetchOrdersByVisits)
    reaction(() => this.requestedTruckVisitIds, this.fetchContainersByVisits)
    reaction(() => this.requestedVesselIds, this.fetchVessels)
    reaction(() => this.requestedTruckIds, this.fetchTrucks)
    reaction(() => this.requestedNnrOrderIds, this.fetchOrdersByNnrOrders)
    reaction(() => this.requestedNnrOrderIdsFromOrders, this.fetchOrdersByNnrOrders)
    reaction(() => this.requestedLinkedOrderIds, this.fetchOrders)
    reaction(() => this.requestedVesselVisitIdsFromOrders, this.fetchVesselVisits)
    reaction(() => this.requestedRailVisitIdsFromOrders, this.fetchRailVisits)
    reaction(() => this.requestedTruckVisitIdsFromOrders, this.fetchTruckVisits)

    reaction(() => this.requestedIssueIds, this.fetchIssues)

    reaction(() => this.requestedOrderIds, this.fetchUnreadUpdatesByOrderIds)
    reaction(() => this.requestedContainerIds, this.fetchContainerJourneys)
  }

  get requestedOrderIds() {
    return _(this.orderStore.elements)
      .keys()
      .map(key => Number(key))
      .uniq()
      .compact()
      .value()
  }

  get requestedContainerIds() {
    return _(this.orderStore.elements)
      .filter(orderItem => !!orderItem.data.containerId)
      .map(orderItem => orderItem.data.containerId!)
      .uniq()
      .compact()
      .value()
  }

  get requestedVesselVisitIds() {
    return _(this.vesselVisitStore.elements)
      .keys()
      .map(key => Number(key))
      .uniq()
      .compact()
      .value()
  }

  get requestedIssueIds() {
    return _(this.orderStore.elements)
      .flatMap(o => o.data.notResolvedIssueIds)
      .uniq()
      .compact()
      .value()
  }

  get requestedRailVisitIds() {
    return _(this.railVisitStore.elements)
      .keys()
      .map(key => Number(key))
      .uniq()
      .compact()
      .value()
  }

  get requestedTruckVisitIds() {
    return _(this.truckVisitStore.elements)
      .keys()
      .map(key => Number(key))
      .uniq()
      .compact()
      .value()
  }

  get requestedVesselIds() {
    return _(this.vesselVisitStore.elements)
      .map(v => v.data.carrierIds)
      .flatten()
      .uniq()
      .value()
  }

  get requestedTruckIds() {
    return _(this.truckVisitStore.elements)
      .map(v => v.data.carrierIds)
      .flatten()
      .uniq()
      .value()
  }

  get requestedNnrOrderIds() {
    return _(this.nnrOrderStore.elements)
      .filter(item => item.data.orderIds.length > 0)
      .map(item => item.data.id)
      .uniq()
      .value()
  }

  get requestedNnrOrderIdsFromOrders() {
    return _(this.orderStore.elements)
      .filter(item => !!item.data.nonNumericOrderId)
      .map(item => item.data.nonNumericOrderId)
      .uniq()
      .compact()
      .value()
  }

  get requestedLinkedOrderIds() {
    return _(this.truckVisitStore.elements)
      .map(v => v.orders)
      .flatten()
      .filter(i => !!i.data.linkedOrderId)
      .map(i => i.data.linkedOrderId!)
      .flatten()
      .uniq()
      .value()
  }

  get requestedVesselVisitIdsFromOrders() {
    return _(this.orderStore.elements)
      .filter(ele => !!ele.data.carrierVisitId && ele.data.carrierVisitType === CarrierType.Vessel)
      .map(ele => ele.data.carrierVisitId!)
      .uniq()
      .compact()
      .value()
  }

  get requestedRailVisitIdsFromOrders() {
    return _(this.orderStore.elements)
      .filter(ele => !!ele.data.carrierVisitId && ele.data.carrierVisitType === CarrierType.Train)
      .map(ele => ele.data.carrierVisitId!)
      .uniq()
      .compact()
      .value()
  }

  get requestedTruckVisitIdsFromOrders() {
    return _(this.orderStore.elements)
      .filter(ele => !!ele.data.carrierVisitId && ele.data.carrierVisitType === CarrierType.Truck)
      .map(ele => ele.data.carrierVisitId!)
      .uniq()
      .compact()
      .value()
  }

  get requestedRailcarTrackPositionsFromRailVisits() {
    return _(this.railVisitStore.elements)
      .flatMap(ele => ele.data.railcarTrackPositionIds ?? [])
      .uniq()
      .compact()
      .value()
  }

  fetchContainerJourneys = async (containerIds: number[]) => {
    return this.containerJourneyItemStore.fetch(containerIds, true)
  }

  fetchVessels = async (ids: number[]) => {
    return this.vesselStore.fetchByIds(ids)
  }

  fetchTrucks = async (ids: number[]) => {
    return this.truckStore.fetchByIds(ids)
  }

  fetchBerths = async () => {
    return this.berthStore.fetch()
  }

  fetchIssues = async () => {
    return this.issueStore.fetch()
  }

  fetchRailTracks = async () => {
    return this.railTrackStore.fetch()
  }

  fetchUnreadUpdatesByOrderIds = async (ids: number[]) => {
    return this.orderUpdateItemStore.fetchByIds(ids)
  }

  fetchRailcarTrackPositionsByIds = async (ids: number[]) => {
    return this.railcarTrackPositionStore.fetchByIds(ids)
  }

  fetchOrdersByVisits = async (visitIds: number[]) => {
    return this.orderStore.fetchByVisitIds(visitIds)
  }

  fetchContainersByVisits = async (visitIds: number[]) => {
    return this.containerStore.fetchByVisitIds(visitIds)
  }

  fetchOrdersByNnrOrders = async (nnrOrderIds: number[]) => {
    return this.orderStore.fetchByNnrOrderIds(nnrOrderIds)
  }

  fetchNnrOrdersByVisits = async (visitIds: number[]) => {
    return this.nnrOrderStore.fetchByVisitIds(visitIds)
  }

  fetchOrders = async (ids: number[]) => {
    return this.orderStore.fetchByIds(ids)
  }

  fetchVesselVisits = async (ids: number[]) => {
    return this.vesselVisitStore.fetchByIds(ids)
  }

  fetchRailVisits = async (ids: number[]) => {
    return this.railVisitStore.fetchByIds(ids)
  }

  fetchTruckVisits = async (ids: number[]) => {
    return this.truckVisitStore.fetchByIds(ids)
  }
}
