import { Grid, TextField, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { UpdateVesselCommand, VesselDto } from '@planning/app/api'
import { vesselService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { useForm } from 'react-hook-form'

export const EditVesselForm = ({ vessel }: { vessel?: VesselDto }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const { appViewStore, drawerStore } = usePlanningStore()
  const { t } = useTranslate()

  const onFormSubmit = async (data: any) => {
    const updatedVessel = data as UpdateVesselCommand
    try {
      if (vessel) {
        updatedVessel.id = vessel.id
        // assign disable and non-registed fields
        updatedVessel.name = vessel.name
        updatedVessel.imoNumber = vessel.imoNumber
        updatedVessel.mmsi = vessel.mmsi
        updatedVessel.callSign = vessel.callSign
        updatedVessel.length = vessel.length
        updatedVessel.width = vessel.width
        updatedVessel.countryCode = vessel.countryCode

        await vesselService.put(updatedVessel)
      }

      appViewStore.setShowAlert(
        'success',
        t('vesselUpdatedSuccessfully', 'Vessel updated successfully'),
      )
      drawerStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  return (
    <form id='edit-vessel-visit-form' onSubmit={handleSubmit(onFormSubmit)}>
      <TextField
        fullWidth
        label={t('name', 'Name')}
        defaultValue={vessel?.name}
        variant='outlined'
        {...register('name', { required: true, disabled: true })}
        name='name'
        error={!!errors.name}
        helperText={errors.name ? t('fieldIsRequired', 'Field is required.') : ''}
        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
      />
      <TextField
        fullWidth
        label={t('imoNumber', 'IMONumber')}
        defaultValue={vessel?.imoNumber}
        variant='outlined'
        {...register('imoNumber', { required: true, disabled: true })}
        name='imoNumber'
        error={!!errors.imoNumber}
        helperText={errors.imoNumber ? t('fieldIsRequired', 'Field is required.') : ''}
        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
      />
      <TextField
        fullWidth
        label={t('shippingLine', 'Shipping Line')}
        defaultValue={vessel?.shippingLine}
        variant='outlined'
        {...register('shippingLine')}
        name='shippingLine'
        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
      />
      <Typography variant='subtitle1'>Dimensions</Typography>
      <Grid container direction='row' spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('lengthM', 'Length (m)')}
            defaultValue={vessel?.length}
            variant='outlined'
            type='number'
            {...register('length', { required: true, valueAsNumber: true, disabled: true })}
            name='length'
            error={!!errors.length}
            helperText={errors.length ? t('fieldIsRequired', 'Field is required.') : ''}
            sx={{ marginTop: '1rem', marginBottom: '1rem' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('beamM', 'Beam (m)')}
            defaultValue={vessel?.width}
            variant='outlined'
            type='number'
            {...register('width', { required: true, valueAsNumber: true, disabled: true })}
            name='width'
            error={!!errors.width}
            helperText={errors.width ? t('fieldIsRequired', 'Field is required.') : ''}
            sx={{ marginTop: '1rem', marginBottom: '1rem' }}
          />
        </Grid>
      </Grid>
    </form>
  )
}
