import { Button } from '@mui/material'
import { GridApi } from '@mui/x-data-grid'
import { NonNumericOrderDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { GridColumn, StickyHeaderTable } from '@planning/components/PaginatedTable'
import { emptyIndicator } from '@planning/constants'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { MutableRefObject } from 'react'
import { OrderItemJourneyStatusChipWrapper } from '../../../components/molecules/ContainerJourneyStatusChipFFWrapper'
import { AssignNNROrderContainer } from './AssignNNROrderContainer'
import { UnassignContainerButton } from './UnassignContainerButton'

interface Props {
  nnrOrder: NonNumericOrderDto
  orderStore: IPaginatedStoreWithItems<IOrderItem>
  apiRef: MutableRefObject<GridApi>
}

export const NNROrderDetailsTable = observer(({ nnrOrder, orderStore, apiRef }: Props) => {
  const { t } = useTranslate()
  const { drawerStore } = usePlanningStore()

  if (!nnrOrder) {
    return <></>
  }

  const getCarrierIdentifier = (order: IOrderItem) => {
    if (!order.visit) return emptyIndicator

    if ('vessels' in order.visit && order.visit.vessels.length)
      return order.visit.vessels[0].data.name
    if ('truck' in order.visit && order.visit.truck) return order.visit.truck.data.licensePlate
    if (
      'railcarEstimate' in order.visit.data &&
      'name' in order.visit.data &&
      order.visit.data.name
    )
      return order.visit.data.name

    return emptyIndicator
  }

  const columns: GridColumn<IOrderItem>[] = [
    {
      field: 'containerNumber',
      headerName: 'Container no',
      flex: 1,
      minWidth: 100,
      sortable: true,
      orderBy: 'containerNumber',
      valueGetter: params => {
        return params.row.data.containerNumber ?? t('toBeAssigned', 'To be assigned')
      },
    },
    {
      field: 'containerLength',
      headerName: 'Length',
      flex: 1,
      minWidth: 100,
      valueGetter: params => {
        return params.row.data.containerLength
          ? `${params.row.data.containerLength}ft`
          : emptyIndicator
      },
      sortable: true,
      orderBy: 'containerLength',
    },
    {
      field: 'containerHeight',
      headerName: 'Height',
      flex: 1,
      minWidth: 100,
      valueGetter: params => {
        return params.row.data.containerHeight ? params.row.data.containerHeight : emptyIndicator
      },
      sortable: true,
      orderBy: 'containerHeight',
    },
    {
      field: 'containerType',
      headerName: 'Type',
      flex: 1,
      minWidth: 100,
      valueGetter: params => {
        return params.row.data.containerType
          ? getContainerTypeParameters(params.row.data.containerType, t)['label']
          : emptyIndicator
      },
      sortable: true,
      orderBy: 'containerType',
    },
    {
      field: 'containerIsoCode',
      headerName: 'ISO Code',
      flex: 1,
      minWidth: 100,
      valueGetter: params => {
        return params.row.data.containerIsoCode ? params.row.data.containerIsoCode : emptyIndicator
      },
      sortable: true,
      orderBy: 'containerIsoCode',
    },
    {
      field: 'carrierIdentifier',
      headerName: 'Carrier Identifier',
      flex: 1,
      minWidth: 120,
      valueGetter: params => getCarrierIdentifier(params.row),
      sortable: true,
      orderBy: 'carrierIdentifier',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      minWidth: 120,
      valueGetter: params => params.row.data.status,
      renderCell: params => <OrderItemJourneyStatusChipWrapper orderItem={params.row} />,
      sortable: true,
      orderBy: 'carrierIdentifier',
    },
    // TODO It is not clear yet, how MaxPayload will get into the system. Add after clarification.
    // {
    //   field: 'containerMaxPayload',
    //   headerName: 'Max. Payload',
    //   flex: 1,
    //   minWidth: 100,
    //   sortable: true,
    //   valueGetter: params => {
    //     const payload = params.row.containerMaxPayload;
    //     if (!payload) return '';

    //     return `${payload}kg`;
    //   },
    //   orderBy: 'containerMaxPayload',
    // },
    {
      field: 'actions',
      headerName: '',
      align: 'center',
      flex: 0.25,
      minWidth: 75,
      sortable: false,
      disableExport: true,
      renderCell: params => {
        if (params.row.data.containerNumber) {
          return (
            <UnassignContainerButton
              order={params.row.data}
              isNNROrderClosed={nnrOrder.isClosed}
              isTruckVisitArrived
            />
          )
        } else {
          return (
            <Button
              variant='text'
              size='small'
              type='submit'
              data-cy='assign-nnr-order'
              onClick={async () => {
                drawerStore.showView(
                  <AssignNNROrderContainer nnrOrder={nnrOrder} orderId={params.row.data.id} />,
                )
              }}
            >
              {t('assign', 'Assign')}
            </Button>
          )
        }
      },
    },
  ]

  return (
    <StickyHeaderTable
      apiRef={apiRef}
      store={orderStore}
      columns={columns}
      getRowId={(row: any) => row.id}
      rowsPerPageOptions={[10, 25, 50]}
    />
  )
})
