import { IssueResponseDto, ObjectType } from '@planning/app/api'
import { IContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourney'
import { ContainerJourneyDataStore } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { EntityItem } from '@planning/rt-stores/base/EntityItem'
import { IEntity } from '@planning/rt-stores/types'
import { computed, makeObservable } from 'mobx'
import { IssueItemStore } from './IssueItemStore'

export interface IIssueItem extends IEntity<IssueResponseDto> {
  containerJourney: IContainerJourney | undefined
}

export class IssueItem extends EntityItem<IssueResponseDto> implements IIssueItem {
  constructor(
    public issueItemStore: IssueItemStore,
    public containerJourneyDataStore: ContainerJourneyDataStore,
    id: number,
    data: IssueResponseDto,
  ) {
    super(id, data)

    makeObservable(this, {
      containerJourney: computed,
    })
  }

  get containerJourney() {
    if (this.data.objectType !== ObjectType.Order) return undefined

    const orderId = this.data.objectId
    return this.containerJourneyDataStore.items.find(
      journey => journey.inboundOrderId === orderId || journey.outboundOrderId === orderId,
    )
  }
}
