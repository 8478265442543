import { Alert, Box, DialogContent, Typography } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection, OrderStatus } from '@planning/app/api'
import { HoldsDrawerButton } from '@planning/components/hold/HoldsDrawerButton'
import { containerService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { ConfirmationActions, useMinimalsTheme } from '@tom-ui/ui'
import { authStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { FC, useMemo, useState } from 'react'
import { IContainerJourney } from '../ServiceOrders/Stores/ContainerJourney'
import { CreateOrderPageForm, IOrderFormData } from './components/CreateOrderPageForm'
import { SelectOrderViewStore } from './stores/SelectOrderViewStore'

interface IProps {
  containerJourney?: IContainerJourney
  forcedDirection?: CarrierVisitDirection
}

export interface IOrderRailInfo {
  railcarTrackPositionId?: number
  railTrackId?: string
  railcarId?: number
}

export const OrderDetails: FC<{
  selectOrderViewStore: SelectOrderViewStore
}> = ({ selectOrderViewStore }) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Box
      sx={{
        display: 'flex',
        mb: '2rem',
        p: '1rem 2rem',
        alignItems: 'center',
        bgcolor: theme.palette.grey[200],
        borderTop: '1px solid rgba(145, 158, 171, 0.24)',
        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
      }}
    >
      <Box mr='1rem'>
        <Typography variant='subtitle1'>{t('orderDetails', 'Order Details')}</Typography>
      </Box>
      <Box>
        <ContainerVisitHoldsDrawerButton selectOrderViewStore={selectOrderViewStore} />
      </Box>
    </Box>
  )
}

export const ContainerVisitHoldsDrawerButton: FC<{
  selectOrderViewStore: SelectOrderViewStore
}> = observer(({ selectOrderViewStore }) => {
  const { inbound, outbound } = selectOrderViewStore.visitOrders

  if (!inbound && !outbound) return <></>

  if (!inbound && outbound) return <HoldsDrawerButton order={outbound} />

  if (inbound && !outbound) return <HoldsDrawerButton order={inbound} />

  return <HoldsDrawerButton order={inbound!} linkedOrder={outbound} />
})

export const EditOrdersPage: FC<IProps> = observer(({ containerJourney, forcedDirection }) => {
  //TODO Refactor to use a new SelectOrderViewStore with useMemo
  const { appViewStore, selectOrderViewStore, dialogStore, tenantStore } = usePlanningStore()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const orderAdminContextMenuFf = useBooleanFlagValue('order-admin-context-menu', false)
  const isAdmin = authStore.isAdmin()

  const [submitLoading, setSubmitLoading] = useState(false)

  const { inbound, outbound } = selectOrderViewStore.visitOrders

  const onEditOrderFormSubmit = async (data: IOrderFormData) => {
    const cmd = selectOrderViewStore.mapOrderFormDataToUpdateContainerVisitCommand(
      data,
      tenantStore.skipReleaseOrder,
      inbound,
      outbound,
    )

    try {
      setSubmitLoading(true)
      await containerService.updateContainerVisit(cmd)
      appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved Successfully'))

      selectOrderViewStore.reset()
      dialogStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    } finally {
      setSubmitLoading(false)
    }
  }

  const isAnyJourneyOrderFulfilledAndUserNonAdmin = useMemo(
    () =>
      orderAdminContextMenuFf &&
      !isAdmin &&
      (inbound?.status === OrderStatus.Fulfilled || outbound?.status === OrderStatus.Fulfilled),
    [orderAdminContextMenuFf, isAdmin, inbound?.status, outbound?.status],
  )

  return (
    <>
      <Typography variant='h4' m='1rem 0' align='center'>
        {t('editOrder', 'Edit Order')}
      </Typography>
      <DialogContent sx={{ paddingX: '0 !important' }}>
        <OrderDetails selectOrderViewStore={selectOrderViewStore} />
        <Box paddingX={theme.customSpacing.l}>
          {isAnyJourneyOrderFulfilledAndUserNonAdmin && containerJourney?.status && (
            <Alert severity='warning' sx={{ marginBottom: theme.customSpacing.m }}>
              {t(
                'cannotEditJourneyWithFulfilledOrders',
                'You cannot edit a journey that has an order with status "{status}"',
                {
                  status: containerJourney.status,
                },
              )}
            </Alert>
          )}
          <CreateOrderPageForm
            forcedDirection={forcedDirection}
            store={selectOrderViewStore}
            onSubmit={onEditOrderFormSubmit}
          />
        </Box>
      </DialogContent>
      <ConfirmationActions
        formId='order-form'
        isLoading={submitLoading}
        primaryActionDisabled={isAnyJourneyOrderFulfilledAndUserNonAdmin}
        primaryActionText={t('submit', 'Submit')}
        closeLabel={t('close', 'Close')}
        onClose={() => {
          dialogStore.close()
        }}
      />
    </>
  )
})
